<template>
  <form @submit.prevent="validate">
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <h5 class="info-text">Are you living in a nice area?</h5>
      </div>
      <div class="col-sm-7">
        <fg-input v-model="streetName" label="Street Name" name="street name">
        </fg-input>
      </div>
      <div class="col-sm-3">
        <fg-input v-model="streetNo" label="Street No" name="street number">
        </fg-input>
      </div>
      <div class="col-sm-5">
        <fg-input v-model="city" label="City" name="city"> </fg-input>
      </div>
      <div class="col-sm-5">
        <label>Country</label>

        <fg-input>
          <el-select v-model="country" class="select-primary" name="country">
            <el-option
              v-for="country in countryOptions"
              :key="country"
              class="select-primary"
              :label="country"
              :value="country"
            ></el-option>
          </el-select>
        </fg-input>
      </div>
    </div>
  </form>
</template>
<script>
import { ElSelect as Select, ElOption as Option } from "element-plus";
//import { extend } from "vee-validate";
//import { required, email } from "vee-validate/dist/rules";

//extend("required", required);
//extend("email", email);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      streetName: "",
      streetNo: "",
      city: "",
      country: "",
      select: null,
      countryOptions: ["One", "Two", "Three", "Four", "Five", "Six"],
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style></style>
