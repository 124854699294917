<template>
  <div class="row">
    <div class="col col-lg-6">
      <chart-card
        :chart-data="companyCommittedHours?.data"
        chart-type="Pie"
        chart-title="Committed hours breakdown"
        :chart-options="companyCommittedHours?.options"
      >
        <template #header>
          <h5 class="card-title">Committed hours breakdown</h5>
          <p class="card-category">Data submitted by employees</p>
        </template>
        <template #title-label> </template>

        <template #footer>
          <hr />
          <MultiFilterToggle
            :type="companyCommittedHours.dateFilter"
            @toggled="companyCommittedHours.updateData"
            @update:type="toggleFilterType = $event"
          />
        </template>
      </chart-card>
    </div>
    <div class="col col-lg-6">
      <chart-card
        :chart-data="customerAcquisition?.data"
        chart-type="Line"
        chart-title="Customer acquisition by order"
        :chart-options="customerAcquisition?.options"
      >
        <template #header>
          <h5 class="card-title">Customer acquisition</h5>
          <p class="card-category">This year trend</p>
        </template>
        <template #title-label> </template>

        <template #footer-title></template>

        <template #footer-right> </template>
      </chart-card>
    </div>
    <div class="col col-md-2">
      <div class="card">
        <div class="card-header">
          <h5>Business margin</h5>
          <p class="card-category">This year value</p>
        </div>
        <div class="card-body">
          <div class="businessmargin-align">
            <p class="large-text">
              {{ businessMargin.current.ratio }}<small>%</small>
            </p>
          </div>
          <div class="businessmargin-align">
            <p class="medium-text">
              {{ businessMargin.current.value }}<small>€</small>
            </p>
          </div>
        </div>
        <div class="card-footer">
          <hr />
        </div>
      </div>
    </div>
    <div class="col col-md-3">
      <div class="card">
        <div class="card-header">
          <h5>Submitted hours analysis</h5>
          <p class="card-category">Submitted hours vs estimates</p>
        </div>
        <div class="card-body">
          <div class="businessmargin-align">
            <p class="large-text">
              {{ submittedAnalysis.current.ratio }}<small>%</small>
            </p>
          </div>
          <div class="businessmargin-align">
            <p
              :class="[
                'medium-text',
                submittedAnalysis.current.value < 0 ? 'text-danger' : '',
              ]"
            >
              {{ submittedAnalysis.current.value }}<small>h</small>
            </p>
          </div>
          <el-select
            v-model="submittedFilter.filterType"
            class="select-default mt-2"
            placeholder="Select filter"
            clearable
            @change="submittedFilter.filter = null"
            @clear="updateSubmittedAnalysis"
          >
            <el-option
              v-for="item in submittedFilter.filterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="submittedFilter.filter"
            class="select-default mt-2"
            filterable
            remote
            :disabled="submittedFilter.filterType == null"
            reserve-keyword
            :placeholder="`Search for ${submittedFilter.label}`"
            :remote-method="submittedFilter.datasource"
            :loading="submittedFilter.loading"
            @change="updateSubmittedAnalysis"
            @focus="submittedFilter.datasource('')"
          >
            <el-option
              v-for="item in submittedFilter.filterData"
              :key="item.id"
              class="select-default"
              :label="item._label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="card-footer">
          <hr />
        </div>
      </div>
    </div>
    <div class="col col-lg-6">
      <chart-card
        :chart-data="ordersStatuses?.data"
        chart-type="Bar"
        chart-title="Orders statuses"
        :chart-options="ordersStatuses?.options"
      >
        <template #header>
          <h5 class="card-title">Order status</h5>
          <p class="card-category">Actual statuses</p>
        </template>
        <template #title-label> </template>

        <template #footer>
          <hr />
          <MultiFilterToggle
            :type="ordersStatuses.dateFilter"
            @toggled="ordersStatuses.updateData"
            @update:type="toggleFilterType = $event"
          />
        </template>
      </chart-card>
    </div>
    <div class="col col-md-3">
      <div class="card">
        <div class="card-header">
          <h5>Development & value</h5>
          <p class="card-category">
            Development time&cost and completed orders' value
          </p>
        </div>
        <div class="card-body">
          <p>Dev:</p>
          <div class="businessmargin-align">
            <p class="medium-text">
              <small>Time:</small> {{ devAndValue.current.devHours }} h
            </p>
          </div>
          <div class="businessmargin-align">
            <p class="medium-text">
              <small>Cost:</small> {{ devAndValue.current.devCosts
              }}<small>€</small>
            </p>
          </div>
          <p>Orders:</p>
          <div class="businessmargin-align">
            <p class="medium-text">
              <small>Value:</small> {{ devAndValue.current.ordersValue
              }}<small>€</small>
            </p>
          </div>
        </div>
        <div class="card-footer">
          <hr />
          <MultiFilterToggle
            :type="devAndValue.dateFilter"
            @toggled="devAndValue.updateData"
            @update:type="toggleFilterType = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartCard from "src/components/UIComponents/Cards/ChartCard";
import { inject, ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { MultiFilterToggle } from "src/components/UIComponents";

const tooltipOptions = {
  tooltipFillColor: "rgba(0,0,0,0.5)",
  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipFontSize: 14,
  tooltipFontStyle: "normal",
  tooltipFontColor: "#fff",
  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipTitleFontSize: 14,
  tooltipTitleFontStyle: "bold",
  tooltipTitleFontColor: "#fff",
  tooltipYPadding: 6,
  tooltipXPadding: 6,
  tooltipCaretSize: 8,
  tooltipCornerRadius: 6,
  tooltipXOffset: 10,
};

export default {
  components: {
    ChartCard,
    MultiFilterToggle,
  },
  setup() {
    const store = useStore();
    const axios = inject("axios"); // inject axios
    const companyCommittedHoursData = ref([]);
    const customerAcquisitionData = ref([]);
    const businessMarginData = ref([]);
    const ordersStatusesData = ref([]);
    const submittedAnalysisData = ref([]);
    const devHoursData = ref([]);
    const devCostsData = ref([]);
    const ordersTotalEstimatedData = ref([]);
    //const businessMarginDataPrevYear = ref([]);

    const createConfig = (token) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      };
      return config;
    };

    const companyCommittedHours = computed(() => ({
      data: {
        labels: companyCommittedHoursData.value.map((el) => el.type),
        datasets: [
          {
            label: "Committed hours",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#BCA3FF", "#3B3BCC", "#fcc468", "#9BFF7D"],
            borderWidth: 0,
            data: companyCommittedHoursData.value.map((el) => el.duration),
          },
        ],
      },
      options: {
        tooltips: tooltipOptions,
      },
      dateFilter: "all",
      updateData: ({ from, to }) => {
        axios
          .get("report", {
            ...createConfig(store.getters["getToken"]),
            params: {
              type: "hours",
              dateStart: from,
              dateEnd: to,
              grouping: ["type"],
            },
          })
          .then((res) => {
            companyCommittedHoursData.value = res.data.data;
          })
          .catch(() => {
            return [0, 0, 0];
          });
      },
    }));

    const customerAcquisition = computed(() => ({
      data: {
        labels: [...customerAcquisitionData.value.map((el) => el.date)],
        datasets: [
          {
            label: "Number of customers",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: "#4acccd",
            borderWidth: 3,
            data: customerAcquisitionData.value.map((el) => ~~el.contacts),
          },
        ],
      },
      options: {
        tooltips: tooltipOptions,
        scales: {
          x: {
            ticks: {
              fontColor: "#9f9f9f",
              beginAtZero: false,
              maxTicksLimit: 5,
              //padding: 20
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)",
            },
          },

          y: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: "#9f9f9f",
            },
          },
        },
      },
    }));

    const businessMargin = reactive({
      current: {
        value: computed(
          () =>
            ~~(
              businessMarginData.value.estimatedCost -
              businessMarginData.value.cost
            )
        ),
        ratio: computed(
          () =>
            ~~(
              (businessMarginData.value.cost /
                businessMarginData.value.estimatedCost) *
              100
            )
        ),
      },
    });

    const ordersStatuses = computed(() => ({
      data: {
        labels: [...ordersStatusesData.value.map((el) => el.status)],
        datasets: [
          {
            label: "Orders",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#BCA3FF", "#3B3BCC", "#fcc468", "#9BFF7D"],
            borderWidth: 0,
            data: ordersStatusesData.value.map((el) => el.number),
          },
        ],
      },
      options: {
        tooltips: tooltipOptions,
        plugins: { legend: { display: false } },
      },
      dateFilter: "all",
      updateData: ({ from, to }) => {
        axios
          .get("report/orders", {
            ...createConfig(store.getters["getToken"]),
            params: {
              dateStart: from,
              dateEnd: to,
            },
          })
          .then((res) => {
            ordersStatusesData.value = res.data.data;
          })
          .catch(() => {});
      },
    }));

    const devAndValue = reactive({
      current: {
        devHours: computed(() =>
          devHoursData.value
            .filter((el) => el.type === "development")
            .reduce((prev, curr) => {
              return prev + ~~curr.duration;
            }, 0)
        ),
        devCosts: computed(() =>
          devCostsData.value
            .filter((el) => el.type === "development")
            .reduce((prev, curr) => {
              return prev + ~~curr.cost;
            }, 0)
        ),
        ordersValue: computed(() =>
          ordersTotalEstimatedData.value
            .filter((el) => el.status !== "offer" && el.status !== "lost")
            .reduce((prev, curr) => {
              return prev + ~~curr.estimatedValue;
            }, 0)
        ),
      },
      dateFilter: "all",
      updateData: ({ from, to }) => {
        axios
          .get("report", {
            ...createConfig(store.getters["getToken"]),
            params: {
              type: "hours",
              dateStart: from,
              dateEnd: to,
              grouping: ["type"],
            },
          })
          .then((res) => {
            devHoursData.value = res.data.data;
          })
          .catch(() => {});
        axios
          .get("report", {
            ...createConfig(store.getters["getToken"]),
            params: {
              type: "costs",
              dateStart: from,
              dateEnd: to,
              grouping: ["type"],
            },
          })
          .then((res) => {
            devCostsData.value = res.data.data;
          })
          .catch(() => {});
        axios
          .get("report/orders", {
            ...createConfig(store.getters["getToken"]),
            params: {
              dateStart: from,
              dateEnd: to,
            },
          })
          .then((res) => {
            ordersTotalEstimatedData.value = res.data.data;
          })
          .catch(() => {});
      },
    });

    const submittedAnalysis = reactive({
      current: {
        value: computed(
          () =>
            ~~(
              submittedAnalysisData.value.estimatedHours -
              submittedAnalysisData.value.actualHours
            )
        ),
        ratio: computed(() => ~~submittedAnalysisData.value.ratio),
      },
    });

    onMounted(async () => {
      companyCommittedHours.value.updateData({});
      axios
        .get("report/closedcustomers", {
          ...createConfig(store.getters["getToken"]),
          params: {
            dateStart: `01-01-${new Date().getFullYear()}`,
            dateEnd: `31-12-${new Date().getFullYear()}`,
          },
        })
        .then((res) => {
          customerAcquisitionData.value = res.data;
        })
        .catch(() => {
          return [0, 0, 0];
        });
      axios
        .get("report/totals", {
          ...createConfig(store.getters["getToken"]),
          params: {
            dateStart: `${new Date().getFullYear()}-01-01`,
            dateEnd: `${new Date().getFullYear()}-12-31`,
          },
        })
        .then((res) => {
          businessMarginData.value = res.data.data;
        })
        .catch(() => {
          return [0, 0, 0];
        });
      ordersStatuses.value.updateData({});
      devAndValue.updateData({});
      updateSubmittedAnalysis();
    });

    const updateSubmittedAnalysis = () =>
      axios
        .get("report/hours", {
          ...createConfig(store.getters["getToken"]),
          params: {
            //dateStart: `${new Date().getFullYear()}-01-01`,
            //dateEnd: `${new Date().getFullYear()}-12-31`,
            personId:
              submittedFilter.filterType == 2 ? submittedFilter.filter : null,
            orderId:
              submittedFilter.filterType == 1 ? submittedFilter.filter : null,
            contactId:
              submittedFilter.filterType == 0 ? submittedFilter.filter : null,
          },
        })
        .then((res) => {
          submittedAnalysisData.value = res.data.data;
        })
        .catch(() => {})
        .finally(() => (submittedFilter.loading = true));

    const submittedFilter = reactive({
      filter: "",
      filterOptions: [
        { label: "Customer", value: 0 },
        { label: "Order", value: 1 },
        { label: "Person", value: 2 },
      ],
      filterType: null,
      label: "",
      datasource: (query) => {
        if (query != null) {
          submittedFilter.loading = true;
          var urlSettings = null;
          switch (submittedFilter.filterType) {
            case 0: {
              urlSettings = {
                endpoint: "contacts/",
                params: {
                  search_term: query,
                  type: "customer",
                  search_fields: ["companyName"],
                },
                label: (el) => el.companyName,
              };
              break;
            }
            case 1: {
              urlSettings = {
                endpoint: "orders/",
                params: {
                  search_term: query,
                  search_fields: ["name"],
                },
                label: (el) => el.name,
              };
              break;
            }
            case 2: {
              urlSettings = {
                endpoint: "persons/",
                params: {
                  search_term: query,
                  search_fields: ["firstName", "lastName"],
                },
                label: (el) => `${el.firstName} ${el.lastName}`,
              };
              break;
            }
          }
          axios
            .get(urlSettings.endpoint, {
              ...createConfig(store.getters["getToken"]),
              params: {
                ...urlSettings.params,
                per_page: "20",
              },
            })
            .then((res) => {
              submittedFilter.filterData = res.data.data.map((el) => ({
                ...el,
                _label: urlSettings.label(el),
              }));
            })
            .catch(() => {})
            .finally(() => {
              submittedFilter.loading = false;
            });
        }
      },
      loading: false,
      filterData: null,
    });

    return {
      companyCommittedHours,
      customerAcquisition,
      businessMargin,
      ordersStatuses,
      devAndValue,
      submittedAnalysis,
      submittedFilter,
      updateSubmittedAnalysis,
    };
  },
};
</script>

<style lang="scss" scoped>
.large-text {
  font-size: 5em;
}
.medium-text {
  font-size: 2.5em;
}

.businessmargin-align {
  display: flex;
  align-items: baseline;
}
</style>
