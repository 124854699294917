<template>
  <div class="row">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <order-card :id="id"> </order-card>
      <!--<members-card> </members-card>-->
    </div>
    <div class="col-lg-5 col-md-6 col-sm-6">
      <contact-card :id="customerId" type="customer"> </contact-card>
      <!--<members-card> </members-card>-->
    </div>

    <div
      v-for="stats in statsCards"
      :key="stats.value"
      class="col-lg-3 col-md-6 col-sm-6"
    >
      <stats-card
        :type="stats.type"
        :icon="stats.icon"
        :small-title="stats.title"
        :title="stats.value.value"
      >
        <template #header>
          <p-button
            type="default"
            link
            @click="$router.push(`/orders/activities/${id}`)"
          >
            Goto Activities
          </p-button>
        </template>
        <template #footer>
          <div class="stats">
            <i :class="stats.footerIcon"></i>
            {{ stats.footerText }}
          </div>
        </template>
      </stats-card>
    </div>

    <div class="col col-lg-6 col-sm-8">
      <chart-card
        :chart-data="orderHoursChart"
        chart-type="Bar"
        :chart-options="orderHoursChart.options"
        chart-title="Order estimated time compared to committed time"
      >
        <template #title>
          <span>Estimated time vs Committed time</span>
        </template>
        <template #title-label> </template>

        <template #footer-title>Overall data submitted by employees</template>

        <template #footer-right> </template>
      </chart-card>
    </div>
    <div class="col col-md-12">
      <order-person-list :id="id"></order-person-list>
    </div>
  </div>
</template>
<script>
//import EditProfileForm from "./EditProfileForm.vue";
import OrderCard from "./OrderCard.vue";
import OrderPersonList from "./OrderPersonsList.vue";
import ContactCard from "../Contacts/ContactCard.vue";
import { useStore } from "vuex";
import { ref, inject, onMounted, computed, onBeforeMount } from "vue";
import ChartCard from "src/components/UIComponents/Cards/ChartCard";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
//import MembersCard from "./MembersCard.vue";

const tooltipOptions = {
  tooltipFillColor: "rgba(0,0,0,0.5)",
  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipFontSize: 14,
  tooltipFontStyle: "normal",
  tooltipFontColor: "#fff",
  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipTitleFontSize: 14,
  tooltipTitleFontStyle: "bold",
  tooltipTitleFontColor: "#fff",
  tooltipYPadding: 6,
  tooltipXPadding: 6,
  tooltipCaretSize: 8,
  tooltipCornerRadius: 6,
  tooltipXOffset: 10,
};

const createConfig = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

export default {
  components: {
    OrderCard,
    ContactCard,
    ChartCard,
    StatsCard,
    OrderPersonList,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const axios = inject("axios"); // inject axios

    const customerId = ref(null);
    const order = ref(null);

    onBeforeMount(() => {
      store.dispatch("orders/clearOrder");
      store.dispatch("contacts/clearContact");
    });

    store.dispatch("orders/fetchOrder", props.id).then(() => {
      order.value = store.state.orders.order;
      customerId.value = order.value.contact.id;
      store.dispatch("contacts/fetchContact", customerId.value);
      store.dispatch("users/fetchUser", order.value.user.id);
    });

    const rawData = ref([]);
    onMounted(() => {
      axios
        .get("report", {
          ...createConfig(store.getters["getToken"]),
          params: {
            type: "hours",
            grouping: ["orderId", "billable"],
            orderId: props.id,
          },
        })
        .then((res) => {
          console.log(res);
          rawData.value = res.data.data;
        })
        .catch(() => {
          //this.error = error;
          //console.log(error);

          rawData.value = [0, 0, 0];
        });

      axios
        .get("report", {
          ...createConfig(store.getters["getToken"]),
          params: {
            type: "hours",
            grouping: ["orderId"],
            orderId: props.id,
          },
        })
        .then((res) => {
          totalHours.value = res.data.data
            .reduce((prev, next) => parseFloat(next.duration), 0)
            .toFixed();
        })
        .catch(() => {
          //this.error = error;
          //console.log(error);

          totalHours.value = 0;
        });
    });
    const orderHoursChart = computed(() => ({
      labels: ["Hours"],
      datasets: [
        {
          label: "Estimated",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#e3e3e3"],
          borderWidth: 0,
          data: [`${order.value?.estimatedTime}`],
          stack: "Stack 0",
        },
        {
          label: "Committed billable",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#4acccd"],
          borderWidth: 0,
          data: [rawData.value.find((el) => el.billable)?.duration],
        },
        {
          label: "Committed not billable",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#4a2acd"],
          borderWidth: 0,
          data: [rawData.value.find((el) => !el.billable)?.duration],
        },
      ],
      options: {
        tooltips: tooltipOptions,
        indexAxis: "y",
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    }));

    /* Stats card */
    const totalHours = ref(0);
    const statsCards = [
      {
        type: "info",
        icon: "nc-icon nc-favourite-28",
        title: "Total commited hours",
        value: computed(
          () => `${totalHours.value}/${order.value?.estimatedTime ?? 0}`
        ),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
      },
    ];

    return {
      statsCards: statsCards,
      orderHoursChart,
      customerId: customerId,
    };
  },
};
</script>
<style></style>
