<template>
  <form @submit.prevent="validate">
    <h5 class="info-text">
      Let's start with the basic information (with validation)
    </h5>
    <div class="row justify-content-center">
      <div class="col-sm-4">
        <div class="picture-container">
          <el-upload
            class="picture"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-change="handlePreview"
            :auto-upload="false"
            :show-file-list="false"
          >
            <img v-if="imageUrl" :src="imageUrl" class="picture-src" />
          </el-upload>
          <h6 class="description">Choose Picture</h6>
        </div>
      </div>
      <div class="col-sm-6">
        <fg-input
          v-model="firstName"
          name="firstName"
          placeholder="First Name (required)"
          addon-left-icon="nc-icon nc-single-02"
        >
        </fg-input>

        <fg-input
          v-model="lastName"
          name="lastName"
          placeholder="Last Name (required)"
          addon-left-icon="nc-icon nc-circle-10"
        >
        </fg-input>
      </div>
      <div class="col-12 col-sm-10 mt-3">
        <fg-input
          v-model="email"
          name="email"
          placeholder="Email (required)"
          addon-left-icon="nc-icon nc-send"
        >
        </fg-input>
      </div>
    </div>
  </form>
</template>
<script>
import { ElUpload as Upload } from "element-plus";
//import { extend } from "vee-validate";
//import { required, email } from "vee-validate/dist/rules";

//extend("required", required);
//extend("email", email);

export default {
  components: {
    [Upload.name]: Upload,
  },
  data() {
    return {
      image: "",
      firstName: "",
      lastName: "",
      email: "",
      imageUrl: "static/img/default-avatar.png",
    };
  },
  methods: {
    handlePreview(file) {
      this.imageUrl.imageUrl = URL.createObjectURL(file.raw);
    },
    validate() {
      return true;
      /*return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res, this.imageUrl);
        return res;
      });*/
    },
  },
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
