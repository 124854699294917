<template>
  <div>
    <div>
      <h1>Welcome {{ user?.firstName }}!</h1>
      <h4>Have a nice day ☕</h4>
    </div>
    <div class="row">
      <div
        v-for="stats in statsCards"
        :key="stats"
        class="col-lg-2 col-md-6 col-sm-6"
      >
        <stats-card
          :type="stats.type"
          :icon="stats.icon"
          :small-title="stats.title"
          :title="stats.value.value"
        >
          <template #footer>
            <div v-if="false" class="stats">
              <i :class="stats.footerIcon"></i>
              {{ stats.footerText }}
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div v-if="userOrders.canSee" class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Working on...</h5>
            <router-link
              class="card-top-action"
              :to="`/persons/details/${user.id}`"
              >View all</router-link
            >
            <p class="card-category">
              Some orders which are currently assigned to you
            </p>
          </div>
          <div class="card-body text-center">
            <el-table
              :data="userOrders.data.value"
              style="width: 100%"
              :show-header="false"
            >
              <el-table-column
                v-for="column in userOrders.userOrdersTableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="column.sortable"
              >
                <template v-if="column.customLayout" #default="scope">
                  <Tag
                    v-if="column.customLayout == 'tag'"
                    :type="'order-' + scope.row?.status"
                  >
                    {{ scope.row?.status }}
                  </Tag>
                </template>
              </el-table-column>
              <el-table-column label="Actions">
                <template #default="scope">
                  <el-button
                    size="small"
                    @click="handleSeeOrder(scope.$index, scope.row)"
                    >Goto</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="false" class="card-footer">
            <hr />
            <small
              >Last month was <b>{{ activeOrders.previous.ratio }}</b></small
            >
          </div>
        </div>
      </div>
      <div v-if="sellerOrders.canSee" class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Old offers</h5>
            <router-link class="card-top-action" :to="`/orders/offers/`"
              >View all</router-link
            >
            <p class="card-category">
              Some very old orders still in offer status
            </p>
          </div>
          <div class="card-body text-center">
            <el-table
              :data="sellerOrders.data.value"
              style="width: 100%"
              :show-header="true"
            >
              <el-table-column
                v-for="column in sellerOrders.userOrdersTableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="column.sortable"
              >
              </el-table-column>
              <el-table-column label="Actions">
                <template #default="scope">
                  <el-button
                    size="small"
                    @click="handleSeeOrder(scope.$index, scope.row)"
                    >Goto</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="false" class="card-footer">
            <hr />
            <small
              >Last month was <b>{{ activeOrders.previous.ratio }}</b></small
            >
          </div>
        </div>
      </div>
      <div v-if="activeOrders.canSee" class="col-md-2">
        <div class="card">
          <div class="card-header">
            <h5>Company active orders</h5>
            <p class="card-category">
              Order in <i>working</i> status as of today
            </p>
          </div>
          <div class="card-body text-center">
            <el-progress
              type="dashboard"
              :percentage="activeOrders.current.ratio"
            >
              <template #default="{ percentage }">
                <span class="percentage-value"
                  >{{ percentage.toFixed(1) }}%</span
                >
                <span class="percentage-label"
                  >{{ activeOrders.current.value }}/{{
                    activeOrders.current.total
                  }}</span
                >
              </template>
            </el-progress>
          </div>
          <div v-if="false" class="card-footer">
            <hr />
            <small
              >Last month was <b>{{ activeOrders.previous.ratio }}</b></small
            >
          </div>
        </div>
      </div>
      <div v-if="workload.canSee" class="col-md-2">
        <div class="card">
          <div class="card-header">
            <h5>Workload</h5>
            <p class="card-category">Average workload</p>
          </div>
          <div class="card-body text-center">
            <el-progress type="dashboard" :percentage="workload.current.value">
              <template #default="{ percentage }">
                <span class="percentage-value"
                  >{{ percentage.toFixed(1) }}%</span
                >
              </template>
            </el-progress>
          </div>
          <div v-if="false" class="card-footer">
            <hr />
            <small
              >Last month was <b>{{ workload.previous.value }}</b></small
            >
          </div>
        </div>
      </div>
      <div v-if="margin.canSee" class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5>Margin</h5>
            <p class="card-category">Business margin</p>
          </div>
          <div class="card-body text-center">
            <div class="row">
              <div class="col-md-4">
                <el-progress type="dashboard" :percentage="margin.total.value">
                  <template #default="{ percentage }">
                    <span class="percentage-value"
                      >{{ percentage.toFixed(1) }}%</span
                    >
                    <span>Total</span>
                  </template>
                </el-progress>
              </div>
              <div class="col-md-4">
                <el-progress
                  type="dashboard"
                  :percentage="margin.working.value"
                >
                  <template #default="{ percentage }">
                    <span class="percentage-value"
                      >{{ percentage.toFixed(1) }}%</span
                    >
                    <span>Working orders</span>
                  </template>
                </el-progress>
              </div>
              <div class="col-md-4">
                <el-progress type="dashboard" :percentage="margin.offers.value">
                  <template #default="{ percentage }">
                    <span class="percentage-value"
                      >{{ percentage.toFixed(1) }}%</span
                    >
                    <span>Offers</span>
                  </template>
                </el-progress>
              </div>
            </div>
          </div>
          <div v-if="false" class="card-footer">
            <hr />
            <small
              >Last month was <b>{{ workload.previous.value }}</b></small
            >
          </div>
        </div>
      </div>
      <div v-if="false" class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5>Total value</h5>
            <p class="card-category">This year confirmed orders' value</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col col-md-4 text-center">
                <h5>{{ confirmedOrders.total }}</h5>
              </div>
              <div class="col col-md-8">
                <bar-chart
                  class="chart-inline"
                  :chart-data="confirmedOrders.chartData.data"
                  :options="confirmedOrders.chartData.options"
                  :labels="confirmedOrders.chartData.labels"
                ></bar-chart>
              </div>
            </div>
          </div>
          <div v-if="false" class="card-footer">
            <hr />
            <small
              >Last month was <b>{{ activeOrders.previous.ratio }}</b></small
            >
          </div>
        </div>
      </div>
      <div v-if="false" class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5>Workload</h5>
            <p class="card-category">This month company average workload</p>
          </div>
          <div class="card-body text-center">
            <el-progress
              type="dashboard"
              :percentage="activeOrders.current.ratio"
            >
            </el-progress>
          </div>
          <div v-if="false" class="card-footer">
            <hr />
            <small
              >Last month was <b>{{ activeOrders.previous.ratio }}</b></small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, reactive, onMounted, inject, ref } from "vue";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import { BarChart } from "vue-chart-3";
import { Tag } from "src/components/UIComponents";
import { useRouter } from "vue-router";
import _ from "lodash";

const createConfig = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

export default {
  components: {
    StatsCard,
    BarChart,
    Tag,
  },
  setup() {
    const store = useStore();
    const axios = inject("axios"); // inject axios
    const router = useRouter();
    const user = computed(() => store.state.user);

    const activeOrders = reactive({
      canSee: user.value?.roles.includes("admin"),
      current: { ratio: 0, value: 0, total: 0 },
      //previous: { ratio: 0, value: 0 },
    });

    const workload = reactive({
      canSee: user.value?.roles.includes("admin"),
      current: { value: 0 },
      //previous: { ratio: 0, value: 0 },
    });

    const margin = reactive({
      canSee: user.value?.roles.includes("admin"),
      total: { value: 0 },
      working: { value: 0 },
      offers: { value: 0 },
    });

    const totalOrdersValue = ref(0);

    onMounted(async () => {});

    /* Stats cards */
    const statsCards = [];

    /* Admin stats cards */

    const adminStatsCards = [
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "Offers",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "canAdminister.offers.total",
      },
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "Closed offers",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "canAdminister.offers.won",
      },
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "Overall sales performance (#)",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "canAdminister.offers.ratio",
      },
      {
        type: "success",
        icon: "nc-icon nc-money-coins",
        title: "Offers total value",
        value: ref(0),
        footerText: "Last day",
        footerIcon: "nc-icon nc-calendar-60",
        prop: "canAdminister.value.total",
      },
      {
        type: "success",
        icon: "nc-icon nc-money-coins",
        title: "Closed offers value",
        value: ref(0),
        footerText: "Last day",
        footerIcon: "nc-icon nc-calendar-60",
        prop: "canAdminister.value.won",
      },
      {
        type: "success",
        icon: "nc-icon nc-money-coins",
        title: "Overall sales performance (€)",
        value: ref(0),
        footerText: "Last day",
        footerIcon: "nc-icon nc-calendar-60",
        prop: "canAdminister.value.ratio",
      },
    ];

    /* User stats cards */
    const userStatsCards = [
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "My ongoing orders",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "ongoingOrders",
      },
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "Remaining assigned time",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "remainingTime",
      },
    ];

    /* Seller stats cards */
    const sellerStatsCards = [
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "This month opened orders",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "openOrders",
      },
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "Performance index (month)",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "monthPerformance",
      },
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "Performance index (year)",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "yearPerformance",
      },
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "This year order's value",
        value: ref(0),
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
        prop: "totalValue",
      },
    ];

    user.value.roles.forEach((el) => {
      switch (el) {
        case "admin": {
          statsCards.push(...adminStatsCards);
          break;
        }
        case "user": {
          statsCards.push(...userStatsCards);
          break;
        }
        case "seller": {
          statsCards.push(...sellerStatsCards);
          break;
        }
        default: {
          break;
        }
      }
    });

    /* Single API dashboard data */
    axios
      .get("report/dashboard", {
        ...createConfig(store.getters["getToken"]),
      })
      .then((res) => {
        statsCards.forEach((el) => {
          var value = _.get(res.data.data, el.prop, null);
          if (value) {
            el.value.value = value;
          }
        });

        //TODO: improve, make this a template
        var apiData = res.data.data;
        if (apiData.canAdminister?.activeOrders) {
          activeOrders.current.value = apiData.canAdminister?.activeOrders;
          activeOrders.current.total = apiData.canAdminister?.workingOrders;
          //activeOrders.current.total = apiData.canAdminister?.activeOrders;
          activeOrders.current.ratio =
            (activeOrders.current.value / activeOrders.current.total) * 100;
        }

        if (apiData.canAdminister?.avgWorkLoad) {
          workload.current.value = apiData.canAdminister?.avgWorkLoad * 100;
        }

        if (apiData.canAdminister?.margin) {
          margin.total.value =
            apiData.canAdminister?.margin.workingAndClosed * 100;
          margin.working.value = apiData.canAdminister?.margin.working * 100;
          margin.offers.value = apiData.canAdminister?.margin.offers * 100;
        }
      })
      .catch(() => {});

    /* User's ongoing orders */
    axios
      .get("report", {
        ...createConfig(store.getters["getToken"]),
        params: {
          type: "hours",
          personId: user.value.id,
          status: "working",
          grouping: ["orderId"],
        },
      })
      .then((res) => {
        const card = statsCards.find((el) => el.prop == "ongoingOrders");
        if (card) {
          card.value.value = res.data.data.length;
        }
      })
      .catch(() => {});

    /* User's orders */
    const userOrders = {
      canSee: user.value?.roles.includes("user"),
      data: ref([]),
      userOrdersTableColumns: [
        {
          prop: "status",
          label: "Status",
          customLayout: "tag",
        },
        {
          prop: "name",
          label: "Name",
        },
      ],
    };

    axios
      .get("report/lastorders", { ...createConfig(store.getters["getToken"]) })
      .then((res) => {
        userOrders.data.value = res.data.data;
      })
      .catch(() => {});

    /* User remaining assigned time */
    axios
      .get("report/remainingtime", {
        ...createConfig(store.getters["getToken"]),
        params: {
          personId: user.value.id,
        },
      })
      .then((res) => {
        const card = statsCards.find((el) => el.prop == "remainingTime");
        if (card) {
          card.value.value = res.data.data.reduce((prev, curr) => {
            return prev + curr.estimatedTime - curr.doneTime;
          }, 0);
        }
      })
      .catch(() => {});

    const handleSeeOrder = (rowId, rowData) => {
      router.push(`/orders/details/${rowData.id}`);
    };

    /* Salesperson stagnant orders */
    const sellerOrders = {
      data: ref([]),
      canSee: user.value?.roles.includes("seller"),
      userOrdersTableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: "100",
        },
        {
          prop: "contact.companyName",
          label: "Customer",
          minWidth: "100",
        },
        {
          prop: "estimatedValue",
          label: "Value",
        },
        {
          prop: "createdAt",
          label: "Since",
        },
      ],
    };
    axios
      .get("report/oldorders", {
        ...createConfig(store.getters["getToken"]),
      })
      .then((res) => {
        const dataset = res.data.data;
        dataset.forEach(
          (el) =>
            (el.createdAt = new Date(el.createdAt).toISOString().slice(0, 10))
        );
        sellerOrders.data.value = dataset;
      })
      .catch(() => {});

    /* Sales-person card's data */
    axios
      .get("report/sellerstats", {
        ...createConfig(store.getters["getToken"]),
      })
      .then((res) => {
        for (const [key, value] of Object.entries(res.data.data)) {
          const card = statsCards.find((el) => el.prop == key);
          if (card) {
            card.value.value = value;
          }
        }
      })
      .catch(() => {});

    return {
      activeOrders,
      workload,
      totalOrdersValue,
      statsCards,
      user,
      userOrders,
      handleSeeOrder,
      sellerOrders,
      margin,
    };
  },
};
</script>
<style scoped>
.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}
.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 12px;
}

.chart-inline {
  max-height: 5em;
}
</style>
