<template>
  <div class="card card-user">
    <div class="image">
      <img src="https://picsum.photos/400" alt="..." />
    </div>
    <div class="card-body">
      <div class="author">
        <a href="#">
          <avatar class="avatar border-gray" :text="fullname" />
          <h5 class="title">{{ user?.firstName }} {{ user?.lastName }}</h5>
        </a>
        <!--<p class="description">@chetfaker</p>-->
      </div>
      <!--<p class="description text-center">
        "I like the way you work it
        <br />
        No diggity <br />
        I wanna bag it up"
      </p>-->
    </div>
    <div class="card-footer">
      <hr />
      <div class="button-container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6 ml-auto">
            <router-link
              v-if="!!user?.person"
              :to="`/persons/details/${user?.person.id}`"
              ><h5>
                <i class="nc-icon nc-single-02"></i>
                <br />
                <small>Person</small>
              </h5>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
            <h5>
              Never
              <br />
              <small>Last seen</small>
            </h5>
          </div>
          <div class="col-lg-3 mr-auto">
            <h5>
              <i class="nc-icon nc-key-25"></i>
              <br />
              <small>Force logout</small>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { Avatar } from "src/components/UIComponents";
//import { useRoute } from "vue-router";

export default {
  components: {
    Avatar,
  },
  props: {
    id: {
      type: Number,
      default: -1,
      description: "Id of the user",
    },
  },
  setup() {
    const store = useStore();
    //const route = useRoute();

    const user = computed(() => store.state.users.user);

    /*const axios = inject("axios"); // inject axios

    const businessImage = ref(null);

    onMounted(async () => {
      businessImage.value = await (async () => {
        axios
          .get("https://picsum.photos/400")
          .then((res) => {
            return res.url;
          })
          .catch((error) => {
            //this.error = error;
            console.log(error);
          });
      })();
    });*/

    return {
      //businessImage,
      user: user,
      fullname: computed(
        () => `${user.value.firstName} ${user.value.lastName}`
      ),
    };
  },
};
</script>
<style></style>
