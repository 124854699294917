<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <h4 class="title">Billable Activities</h4>
      </div>
      <div class="col-md-2 mt-3">
        <p-button
          :round="true"
          class="pull-right"
          :disabled="selectedCounter <= 0"
          @click="showBalanceModal = true"
        >
          Bill {{ selectedCounter }} Activities
        </p-button>
      </div>
      <div class="col-md-12 card">
        <div class="card-header">
          <div class="category">Activities</div>
        </div>
        <div class="card-body row">
          <div class="col-sm-6">
            <el-select
              v-model="pagination.perPage"
              class="select-default"
              placeholder="Per page"
            >
              <el-option
                v-for="item in pagination.perPageOptions"
                :key="item"
                class="select-default"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4">
            <div class="pull-right">
              <el-date-picker
                v-model="dateRangeFilter"
                type="daterange"
                unlink-panels
                range-separator="To"
                value-format="YYYY-MM-DD"
                start-placeholder="Start date"
                end-placeholder="End date"
                :shortcuts="shortcuts"
                @change="onDateRangeChange()"
              />
            </div>
          </div>
          <div class="col-sm-2">
            <div class="pull-right">
              <el-input
                v-model="searchQuery"
                class="input-sm"
                placeholder="Search"
              >
                <template #suffix>
                  <el-icon class="el-input__icon nc-icon nc-zoom-split" />
                </template>
              </el-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table
              ref="activitiesTable"
              class="table-striped"
              :data="tableData"
              border
              style="width: 100%"
              :default-sort="{ prop: sorting.column, order: sorting.order }"
              row-key="id"
              @sort-change="sortTable($event)"
              @select="onRowSelection($event)"
              @select-all="onAllRowsSelection($event)"
            >
              <el-table-column type="selection" :reserve-selection="true">
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="column.sortable"
              >
                <template
                  v-if="column.customLayout || column.computed"
                  #default="scope"
                >
                  <template v-if="column.computed">
                    {{ column.computed?.(scope.row) }}
                  </template>
                  <Tag
                    v-if="column.customLayout?.component == 'tag'"
                    :type="'activity-' + scope.row?.type"
                  >
                    {{ scope.row?.type }}
                  </Tag>
                </template>
              </el-table-column>
              <el-table-column
                :min-width="120"
                fixed="right"
                class-name="td-actions"
                label="Actions"
              >
                <template #default="props">
                  <p-button
                    type="info"
                    size="sm"
                    icon
                    @click="handleShow(props.$index, props.row)"
                  >
                    <i class="fa fa-user"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">
              Showing {{ from + 1 }} to {{ to }} of {{ total() }} entries
            </p>
          </div>
          <div class="col-sm-6">
            <p-pagination
              v-model="pagination.currentPage"
              class="pull-right"
              :per-page="pagination.perPage"
              :total="pagination.total"
              @input="pagination.currentPage = $event"
            >
            </p-pagination>
          </div>
        </div>
        <!-- small modal -->
        <activity-modal
          :show-modal="showModal"
          :show-close="false"
          :mode-insert="false"
          :schema="schema"
          :should-reset-after="true"
          :data="modalDataToShow"
          @onClose="showModal = false"
        >
        </activity-modal>
        <balance-modal
          v-if="selectedCounter > 0"
          :activities="selectedActivities"
          :show-modal="showBalanceModal"
          @onClose="showBalanceModal = false"
          @onSave="onSave()"
        >
        </balance-modal>
      </div>
    </div>
  </div>
</template>

<script>
import PPagination from "src/components/UIComponents/Pagination.vue";
import { useStore } from "vuex";
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import ActivityModal from "./ActivityModal.vue";
import BalanceModal from "./BalanceModal.vue";
import { object, string, date, number, bool } from "yup";
import { ElSelect } from "element-plus";
import { Tag } from "src/components/UIComponents";
import _ from "lodash";

const activityTypes = [
  { key: "development", label: "Development" },
  { key: "call", label: "Call" },
  { key: "meeting", label: "Meeting" },
  { key: "helpdesk", label: "Helpdesk" },
];

export default defineComponent({
  components: {
    PPagination,
    ElSelect,
    ActivityModal,
    BalanceModal,
    Tag,
  },
  props: {},
  setup() {
    const store = useStore();

    /* Save Activity Form */
    const modalDataToShow = toRefs(
      reactive({
        entityId: null,
        date: null,
        description: null,
        duration: null,
        personId: null,
        type: null,
        billable: false,
        createdBy: null,
      })
    );

    /* Validation */
    const schema = object().shape(
      {
        entityId: string().required("This field is required"),
        date: date()
          .required()
          .typeError("This field must be a valid date dd/mm/yyyy"),
        description: string().nullable(true).required("This field is required"),
        duration: number()
          .positive()
          .required()
          .typeError("This field is required"),
        personId: number().required(),
        type: string().required().typeError("This field is required"),
        billable: bool().default(false),
      },
      [["orderId", "contactId"]]
    );

    /* Table */
    const activitiesTable = ref(null);
    const tableColumns = [
      {
        prop: "order",
        computed: (rowdata) => {
          return rowdata.order?.name ?? "";
        },
        propertyProp: ["order.name"],
        label: "Order",
        minWidth: 120,
        searchable: true,
      },
      {
        prop: "contact",
        computed: (rowdata) => {
          const customerSource = rowdata.contact ?? rowdata.order.contact;
          return customerSource
            ? customerSource.firstName + " " + customerSource.lastName
            : "";
        },
        propertyProp: ["contact.firstName", "contact.lastName"], //order by and search on
        label: "Customer",
        minWidth: 120,
        searchable: true,
      },
      {
        prop: "type",
        label: "Type",
        minWidth: 100,
        sortable: "custom",
        customLayout: {
          component: "tag",
        },
        searchable: true,
      },
      { prop: "date", label: "Date", sortable: "custom" },
      {
        prop: "description",
        label: "Description",
        minWidth: 250,
        searchable: true,
      },
      {
        prop: "duration",
        label: "Duration",
        minWidth: 50,
      },
    ];

    /* Table sorting */
    const sorting = reactive([
      {
        column: "date",
        order: "desc",
      },
    ]);
    const sortTable = ({ prop, order }) => {
      sorting.splice(0, sorting.length);

      //TODO : activate multi ordering when available
      sorting.push(...sorting.filter((el) => el.column != prop));

      if (sorting.length == 0 && 1 == 2) {
        sorting.push({
          column: "date",
          order: "desc",
        });
      }

      if (order != null) {
        sorting.push({
          column: prop,
          order: [
            { key: "ascending", value: "asc" },
            { key: "descending", value: "desc" },
          ].find((el) => el.key === order).value,
        });
      }
    };

    /* Table filtering */
    const propsToSearch = tableColumns
      .filter((el) => el.searchable)
      .map((el) => el.propertyProp ?? el.prop)
      .flat();

    const dateRangeFilter = ref([]);
    const shortcuts = [
      {
        text: "Last week",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "Last month",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "Last 3 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
      {
        text: "Last quarter",
        value: () => {
          const end = new Date();
          const start = new Date();
          const q = Math.floor(end.getMonth() / 3);
          start.setMonth(0 + 3 * q);
          start.setDate(1);
          return [start, end];
        },
      },
    ];

    const onDateRangeChange = () => {
      fetchTableData();
    };

    /* Table settings */
    const pagination = reactive({
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      total: 0,
    });

    const searchQuery = ref("");

    const fetchTableData = async () => {
      await store
        .dispatch("activities/fetchActivities", {
          page: pagination.currentPage,
          perPage: pagination.perPage,
          searchTerm: searchQuery.value,
          searchFields: propsToSearch,
          orderId: null,
          sorting,
          billable: true,
          billed: false,
          dateRange: dateRangeFilter.value,
        })
        .then((result) => {
          pagination.total = result.total;
        });

      //Update table row selection
      const selected = activitiesTable.value.getSelectionRows();
      const filteredSelection = selected.filter(
        (el) =>
          el.id ==
          store.state.activities.activities.find(
            (tableEl) => el.id == tableEl.id
          )?.id
      );
      selected.forEach((el) => {
        if (!filteredSelection.find((searchEl) => searchEl.id == el.id)) {
          activitiesTable.value.toggleRowSelection(el, false);
        }
      });

      updateSelection();
    };

    /* Table row selection */
    const selectedCounter = ref(null);
    const selectedActivities = ref(null);

    const updateSelection = () => {
      selectedActivities.value = activitiesTable.value.getSelectionRows();
      selectedCounter.value =
        selectedActivities.value.length > 0
          ? selectedActivities.value.length
          : "";
    };
    const onRowSelection = () => {
      updateSelection();
    };

    const onAllRowsSelection = () => {
      updateSelection();
    };

    watch(
      [
        sorting,
        () => pagination.perPage,
        () => pagination.currentPage,
        searchQuery,
      ],
      _.debounce(async () => {
        fetchTableData();
      }, 500)
    );

    onMounted(() => {
      fetchTableData();
    });

    /* Balance Modal */
    const showBalanceModal = ref(false);
    const onSave = () => {
      fetchTableData();
    };

    /*Detail Modal */
    const showModal = ref(false);

    const handleShow = (index, row) => {
      const rowdata = store.getters["activities/getActivity"](row.id);

      store.dispatch("users/fetchUser", rowdata.user.id).then(() => {
        modalDataToShow.createdBy.value =
          store.state.users.user.firstName +
          " " +
          store.state.users.user.lastName;
      });
      store.dispatch("persons/fetchPerson", rowdata.person.id).then(() => {
        modalDataToShow.personId.value =
          store.state.persons.person.firstName +
          " " +
          store.state.persons.person.lastName;
      });

      var entityName = "";
      if (rowdata.order) {
        entityName = rowdata.order.name;
      } else if (rowdata.contact) {
        entityName = rowdata.contact.firstName + " " + rowdata.contact.lastName;
      }
      modalDataToShow.entityId.value = entityName;

      modalDataToShow.date.value = rowdata.date;
      modalDataToShow.description.value = rowdata.description;
      modalDataToShow.duration.value = rowdata.duration;

      modalDataToShow.type.value = activityTypes.find(
        (el) => el.key === rowdata.type
      )?.label;
      modalDataToShow.billable.value = ["No", "Yes"][rowdata.billable];
      modalDataToShow.duration.value = rowdata.duration;

      showModal.value = true;
    };

    return {
      schema,
      sorting,
      sortTable,
      pagination,
      searchQuery,
      tableData: computed(() => store.state.activities.activities),
      showModal,
      modalDataToShow,
      tableColumns,
      selectedCounter,
      selectedActivities,
      onRowSelection,
      activitiesTable,
      onAllRowsSelection,
      handleShow,
      shortcuts,
      dateRangeFilter,
      onDateRangeChange,
      showBalanceModal,
      onSave,
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total() < highBound) {
        highBound = this.total();
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  methods: {
    total() {
      return this.pagination.total;
    },
  },
});
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
