<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <div class="col-md-12 title-filters">
        <h4 class="title">The numbers</h4>
        <MultiFilterToggle
          :type="toggleFilterType"
          @toggled="updateCards"
          @update:type="toggleFilterType = $event"
        />
      </div>
      <div
        v-for="(stats, i) in statsCards"
        :key="`${stats.value}_${i}`"
        class="col-lg-2 col-md-6 col-sm-6"
      >
        <stats-card
          :type="stats.type"
          :icon="stats.icon"
          :small-title="stats.title"
          :title="stats.value"
        >
          <template #footer>
            <div v-if="false" class="stats">
              <i :class="stats.footerIcon"></i>
              {{ stats.footerText }}
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <orders-list :type="type"> </orders-list>
  </div>
</template>

<script>
import OrdersList from "./OrdersList";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import { MultiFilterToggle } from "src/components/UIComponents";
import { onMounted, inject, ref, watch } from "vue";
import { useStore } from "vuex";

const createConfig = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

export default {
  components: {
    OrdersList,
    StatsCard,
    MultiFilterToggle,
  },
  props: {
    type: {
      type: Array,
      default: () => ["offer", "completed", "working", "blocked", "lost"],
      description: "A filter to show only specific order statuses data",
    },
  },
  setup(props) {
    const store = useStore();
    const axios = inject("axios"); // inject axios

    onMounted(() => updateCards({}));

    const updateCards = ({ from, to }) => {
      total.value = 0;
      axios
        .get("report/orders", {
          ...createConfig(store.getters["getToken"]),
          params: {
            dateStart: from,
            dateEnd: to,
          },
        })
        .then((res) => {
          res.data.data.forEach((stat) => {
            const card = statsCards.value.find((el) => el.prop == stat.status);
            if (card) {
              card.value = stat.number;
              total.value += stat.number;
            }
          });
        })
        .catch(() => {
          return [0, 0, 0];
        });
    };

    const total = ref(0);
    const statsCards = ref([]);

    const getCards = () =>
      [
        {
          type: "warning",
          icon: "nc-icon nc-globe",
          title: "Offer",
          value: ref(0),
          footerText: "Updated now",
          footerIcon: "nc-icon nc-refresh-69",
          prop: "offer",
        },
        {
          type: "success",
          icon: "nc-icon nc-money-coins",
          title: "Working",
          value: ref(0),
          footerText: "Last day",
          footerIcon: "nc-icon nc-calendar-60",
          prop: "working",
        },
        {
          type: "danger",
          icon: "nc-icon nc-vector",
          title: "Completed",
          value: ref(0),
          footerText: "In the last hour",
          footerIcon: "nc-icon nc-bell-55",
          prop: "completed",
        },
        {
          type: "danger",
          icon: "nc-icon nc-vector",
          title: "Blocked",
          value: ref(0),
          footerText: "In the last hour",
          footerIcon: "nc-icon nc-bell-55",
          prop: "blocked",
        },
        {
          type: "info",
          icon: "nc-icon nc-favourite-28",
          title: "Lost",
          value: ref(0),
          footerText: "Updated now",
          footerIcon: "nc-icon nc-refresh-69",
          prop: "lost",
        },
        {
          type: "info",
          icon: "nc-icon nc-favourite-28",
          title: "Total",
          value: total,
          footerText: "Updated now",
          footerIcon: "nc-icon nc-refresh-69",
        },
      ].filter((el) => !el.prop || props.type.find((prp) => prp == el.prop));

    statsCards.value = getCards();
    const toggleFilterType = ref("all");

    watch(
      () => props.type,
      () => {
        toggleFilterType.value = "all";
        statsCards.value = getCards();
        updateCards({});
      }
    );

    return {
      statsCards,
      updateCards,
      toggleFilterType,
    };
  },
};
</script>

<style scoped>
.title-filters {
  display: flex;
  align-items: flex-end;
}
</style>
