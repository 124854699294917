<template>
  <div class="row">
    <div class="col-lg-4 col-md-5">
      <user-card :id="id"> </user-card>
      <!--<members-card> </members-card>-->
    </div>
    <div class="col-lg-8 col-md-7">
      <edit-user-form :id="id"> </edit-user-form>
    </div>
    <div class="col-lg-4 col-md-5">
      <roles-card :id="id" :mode="'edit'"> </roles-card>
    </div>
  </div>
</template>
<script>
import EditUserForm from "./EditUserForm.vue";
import RolesCard from "./RolesCard.vue";
import UserCard from "./UserCard.vue";
//import MembersCard from "./MembersCard.vue";
import { useStore } from "vuex";
import { onMounted, watch } from "vue";

export default {
  components: {
    EditUserForm,
    UserCard,
    //MembersCard,
    RolesCard,
  },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const store = useStore();
    onMounted(() => {
      store.dispatch("users/fetchUser", props.id);
    });

    watch(
      () => props.id,
      () => {
        store.dispatch("users/fetchUser", props.id);
      }
    );
  },
};
</script>
<style></style>
