<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Edit User</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-5">
            <fg-input
              type="text"
              label="Email"
              placeholder="@email"
              name="email"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input
              type="text"
              label="First Name"
              placeholder="First Name"
              name="firstName"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input
              type="text"
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
            >
            </fg-input>
          </div>
        </div>
        <div v-if="mode === 'edit'" class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            :disabled="isSubmitting"
            @click.prevent="onSubmit"
          >
            Update user
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { object, string } from "yup";
import { useForm } from "vee-validate";
import { computed, ref } from "vue";

export default {
  props: {
    mode: {
      type: String,
      default: "edit",
      description: "form in show|edit|create mode",
    },
    id: {
      type: Number,
      default: null,
      description: "Id of the user",
    },
  },
  setup(props) {
    const store = useStore();
    //const route = useRoute();
    const user = computed(() => store.state.users.user);

    const defaults = ref(user);

    const schema = object().shape({
      email: string().email().required("This field is required"),
      firstName: string().required("This field is required"),
      lastName: string().required("This field is required"),
    });

    const { handleSubmit, validate, values } = useForm({
      validationSchema: schema,
      initialValues: defaults,
    });

    const isSubmitting = ref(false);
    const onSubmit = handleSubmit((values) => {
      isSubmitting.value = true;
      store
        .dispatch("users/modifyUser", { id: props.id, userValues: values })
        .then(({ result, message }) => {
          if (result === true) {
            // reset the form and the field values to their initial values
            //resetForm();
          } else {
            console.log(message);
            //errorMessage.value = message;
          }
        })
        .finally(() => (isSubmitting.value = false));
    });

    return {
      onSubmit,
      user,
      isSubmitting,
      async validate() {
        return { result: await validate(), values: values };
      },
    };
  },
};
</script>
<style></style>
