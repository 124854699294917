<template>
  <div class="card">
    <div class="card-body">
      <div class="text-center">
        <h5 class="title">{{ order?.name }}</h5>
      </div>
      <p class="description text-center">
        {{ order?.description }}
      </p>
      <div class="side-by-side">
        <drop-down class="btn-group">
          <template #title="{ isOpen }">
            <p-button
              type="neutral"
              outline
              size="sm"
              :aria-expanded="isOpen"
              class="mr-2"
            >
              Edit
            </p-button>
          </template>
          <a
            v-for="status in statuses"
            :key="status.name"
            class="dropdown-item"
            @click="changeStatus(status.value)"
            ><Tag :type="'order-' + status.value">
              {{ status.name }}
            </Tag></a
          >
        </drop-down>

        State
        <Tag
          v-if="orderStatus"
          :type="'order-' + orderStatus.value"
          class="tag-fix"
        >
          {{ orderStatus.name }}
        </Tag>
      </div>
      <div class="row">
        <div class="offset-md-3 col-md-6 mb-4 mt-3">
          <el-progress
            :text-inside="true"
            :stroke-width="26"
            :percentage="orderProgress < 100 ? orderProgress : 100"
          >
            <template v-if="orderProgress > 100" #default>
              {{ orderProgress }}%
            </template>
          </el-progress>
        </div>
      </div>
      <p class="text-center">
        Start date {{ order?.validityStart }}
        <br />
        End date {{ order?.validityEnd }}
      </p>
      <div class="row mt-2">
        <div class="col-lg-6 col-md-6 col-6 ml-auto text-center">
          <h5>
            {{ order?.estimatedValue }} <small>€</small>
            <br />
            <small>Order value</small>
          </h5>
        </div>
        <div class="col-lg-6 col-md-6 col-6 ml-auto text-center">
          <h5>
            {{ order?.estimatedTime }} <small>ore</small>
            <br />
            <small>Estimated time</small>
          </h5>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <hr />
      <div class="button-container">
        <div class="row">
          <div class="col-lg-12 col-md-6 col-6 ml-auto mr-auto">
            <small>
              Created
              <Timeago locale="en" :datetime="order?.createdAt"></Timeago> by
            </small>
            <avatar class="avatar border-gray" :text="fullname" size="small" />
            <small class="ml-1"
              >{{ user?.firstName }} {{ user?.lastName }}</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, inject, watch } from "vue";
import { useStore } from "vuex";
//import { useRoute } from "vue-router";
import { Timeago } from "vue2-timeago";
import { Avatar } from "src/components/UIComponents";
import { Tag } from "src/components/UIComponents";

const createConfig = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

export default {
  components: {
    Timeago,
    Avatar,
    Tag,
  },
  props: {
    id: {
      type: Number,
      default: -1,
      description: "Id of the contact",
    },
  },
  setup(props) {
    const store = useStore();
    //const route = useRoute();
    const order = computed(() => store.state.orders.order);
    const user = computed(() => store.state.users.user);
    const axios = inject("axios"); // inject axios

    const statuses = [
      { name: "Offer", value: "offer" },
      { name: "Working", value: "working" },
      { name: "Completed", value: "completed" },
      { name: "Blocked", value: "blocked" },
      { name: "Lost", value: "lost" },
    ];

    const orderProgress = ref(0);

    watch(order, () => {
      if (order.value.id) {
        axios
          .get("report", {
            ...createConfig(store.getters["getToken"]),
            params: {
              type: "hours",
              grouping: ["orderId"],
              orderId: order.value.id,
            },
          })
          .then((res) => {
            if (res.data.data[0]?.duration && order.value.estimatedTime) {
              orderProgress.value = parseFloat(
                (
                  (res.data.data[0]?.duration * 100) /
                  order.value.estimatedTime
                ).toFixed(2)
              );
            } else {
              orderProgress.value = 0;
            }
          })
          .catch(() => {
            orderProgress.value = 0;
          });
      }
    });

    return {
      orderProgress,
      //businessImage,
      fullname: computed(
        () => `${user.value?.firstName} ${user.value?.lastName}`
      ),
      order,
      user: ref(user),
      orderStatus: computed(() =>
        statuses.find((el) => el.value == order.value?.status)
      ),
      statuses: statuses,
      changeStatus: (newStatus) => {
        const modOrder = { ...order.value };
        modOrder.status = newStatus;
        store
          .dispatch("orders/updateOrder", {
            orderValues: modOrder,
            id: props.id,
          })
          .then(({ result }) => {
            if (result === true) {
              console.log("show message");
            }
          });
      },
    };
  },
};
</script>
<style scoped>
.side-by-side {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-fix {
  margin-bottom: 0px;
}
</style>
