<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Roles</h4>
    </div>
    <div class="card-body">
      <form>
        <fg-input :model-value="selects.multiple" name="roles">
          <template #default="slotProps">
            <el-select
              v-model="slotProps.inputValue"
              multiple
              class="select-default"
              size="large"
              placeholder="Multiple Select"
              @change="slotProps.updateValue($event)"
            >
              <el-option
                v-for="option in selects.roles"
                :key="option.label"
                class="select-default"
                :value="option.value"
                :label="option.label"
              >
              </el-option>
            </el-select>
          </template>
        </fg-input>
      </form>
    </div>
    <div v-if="mode === 'edit'" class="card-footer">
      <div class="text-center">
        <button
          type="submit"
          class="btn btn-info btn-fill btn-wd"
          :disabled="isSubmitting"
          @click.prevent="updateRoles"
        >
          Update roles
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
//import { useRoute } from "vue-router";
import { useForm } from "vee-validate";
import { object, array, string } from "yup";

import { ElSelect as Select } from "element-plus";

export default {
  components: {
    [Select.name]: Select,
  },
  props: {
    id: {
      type: Number,
      default: -1,
      description: "Id of the user",
    },
    mode: {
      type: String,
      default: "edit",
      description: "form in show|edit|create mode",
    },
  },
  setup(props) {
    const store = useStore();
    //const route = useRoute();
    const user = computed(() => store.state.users.user);
    const defaults = ref(user);

    const schema = object().shape({
      roles: array(string().required())
        .min(1)
        .typeError("This field is required"),
    });

    const { handleSubmit, validate, values } = useForm({
      validationSchema: schema,
      initialValues: defaults,
    });

    const isSubmitting = ref(false);
    const updateRoles = handleSubmit((values) => {
      isSubmitting.value = true;
      store
        .dispatch("users/modifyUser", { id: props.id, roleValues: values })
        .then(({ result, message }) => {
          if (result === true) {
            // reset the form and the field values to their initial values
            //resetForm();
          } else {
            console.log(message);
            //errorMessage.value = message;
          }
        })
        .finally(() => (isSubmitting.value = false));
    });

    return {
      user,
      updateRoles,
      selects: ref({
        roles: [
          { value: "user", label: "User" },
          { value: "superadmin", label: "Jaics admin" },
          { value: "admin", label: "Administrator" },
          { value: "seller", label: "Seller" },
          { value: "project manager", label: "Project Manager" },
        ],
        multiple: null,
      }),
      validate: async () => {
        return { result: await validate(), values: values };
      },
    };
  },
};
</script>
<style></style>
