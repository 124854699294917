<template>
  <div class="row">
    <div class="col-md-10">
      <h4 class="title">Activities</h4>
    </div>
    <div class="col-md-2 mt-3">
      <p-button :round="true" class="pull-right" @click="handleAddActivity"
        >Add Activity</p-button
      >
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Activities</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            v-model="pagination.perPage"
            class="select-default"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-default"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <el-input
              v-model="searchQuery"
              class="input-sm"
              placeholder="Search"
            >
              <template #suffix>
                <el-icon class="el-input__icon nc-icon nc-zoom-split" />
              </template>
            </el-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
            :default-sort="{ prop: sorting.column, order: sorting.order }"
            @sort-change="sortTable($event)"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.propertyProp?.[0] ?? column.prop"
              :label="column.label"
              :sortable="column.sortable"
            >
              <template
                v-if="column.customLayout || column.computed"
                #default="scope"
              >
                <template v-if="column.computed">
                  {{ column.computed?.(scope.row) }}
                </template>
                <Tag
                  v-if="column.customLayout?.component == 'tag'"
                  :type="'activity-' + scope.row?.type"
                >
                  {{ scope.row?.type }}
                </Tag>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              fixed="right"
              class-name="td-actions"
              label="Actions"
            >
              <template #default="props">
                <p-button
                  type="info"
                  size="sm"
                  icon
                  @click="handleShow(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total() }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            v-model="pagination.currentPage"
            class="pull-right"
            :per-page="pagination.perPage"
            :total="pagination.total"
            @input="pagination.currentPage = $event"
          >
          </p-pagination>
        </div>
      </div>
      <!-- small modal -->
      <activity-modal
        :show-modal="showModal"
        :show-close="false"
        :mode-insert="modalModeInsert"
        :submit-action="submitAction"
        :should-reset-after="true"
        :data="modalDataToShow"
        @onClose="showModal = false"
      >
      </activity-modal>
    </div>
  </div>
</template>

<script>
import PPagination from "src/components/UIComponents/Pagination.vue";
import { useStore } from "vuex";
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import ActivityModal from "./ActivityModal.vue";

import { ElSelect } from "element-plus";
import { Tag } from "src/components/UIComponents";
import _ from "lodash";
import Swal from "sweetalert2";

const activityTypes = [
  { key: "development", label: "Development" },
  { key: "call", label: "Call" },
  { key: "meeting", label: "Meeting" },
  { key: "helpdesk", label: "Helpdesk" },
];

export default defineComponent({
  components: {
    PPagination,
    ElSelect,
    ActivityModal,
    Tag,
  },
  props: {
    filter: {
      type: Array,
      description: "Array of filters",
      default: () => [],
    },
  },
  emits: ["newActivity"],
  setup(props, context) {
    const store = useStore();

    /* Save Activity Form */
    const modalDataToShow = toRefs(
      reactive({
        entityId: null,
        date: null,
        description: null,
        duration: null,
        personId: null,
        type: null,
        billable: false,
        createdBy: null,
      })
    );

    /* Current user default person */
    watch(
      () => store.state.user,
      (user) => {
        if (user.person?.id)
          store.dispatch("persons/fetchPerson", user.person.id);
      }
    );

    const submitAction = (values) => {
      //TODO find a better way, maybe using object as values
      const [type, id] = values.entityId.split("_");
      if (type === "contact") {
        Object.assign(values, { contactId: id });
      }
      if (type === "order") {
        Object.assign(values, { orderId: id });
      }

      store
        .dispatch("activities/createActivity", values)
        .then(async ({ result, message }) => {
          if (result === true) {
            // reset the form and the field values to their initial values
            showModal.value = false;
            fetchTableData();
          } else {
            console.log(message);
            //errorMessage.value = message;
          }
          context.emit("newActivity", result);
        });
    };

    /* Table column definition */
    const tableColumns = [
      {
        prop: "type",
        label: "Type",
        minWidth: 100,
        sortable: "custom",
        customLayout: {
          component: "tag",
        },
        searchable: true,
      },
      { prop: "date", label: "Date", sortable: "custom" },
      {
        prop: "description",
        label: "Description",
        minWidth: 250,
        searchable: true,
      },
      {
        prop: "duration",
        label: "Duration",
        minWidth: 50,
      },
      {
        prop: "person",
        propertyProp: ["person.firstName", "person.lastName"], //order by and search on
        computed: (rowdata) => {
          return (
            rowdata.person?.firstName + " " + rowdata.person?.lastName ?? ""
          );
        },
        label: "Person",
        minWidth: 90,
        sortable: "custom",
        searchable: true,
      },
    ];

    /* Table filtering */
    let orderFilter = {
      prop: "order",
      computed: (rowdata) => {
        return rowdata.order?.name ?? "";
      },
      label: "Order",
      minWidth: 120,
      sortable: "custom",
      propertyProp: ["order.name"],
      searchable: true,
    };
    if (!props.filter.find((element) => element.key == "orderId")) {
      tableColumns.splice(0, 0, orderFilter);
    }

    let customerFilter = {
      prop: "contact",
      computed: (rowdata) => {
        return (
          rowdata.contact?.companyName ?? rowdata.order?.contact.companyName
        );
      },
      label: "Customer",
      minWidth: 120,
      sortable: "custom",
      propertyProp: ["contact.firstName", "contact.lastName"], //order by and search on
      searchable: true,
    };
    if (!props.filter.find((element) => element.key == "orderId")) {
      tableColumns.splice(1, 0, customerFilter);
    }

    /*const propsToSearch = [
      orderFilter[0]?.propertyProp,
      customerFilter[0]?.propertyProp,
      "type",
      "description",
      "personName",
    ].filter((el) => el);*/

    const propsToSearch = tableColumns
      .filter((el) => el.searchable)
      .map((el) => el.propertyProp ?? el.prop)
      .flat();
    /* Table sorting */
    const sorting = reactive([
      {
        column: "date",
        order: "desc",
      },
    ]);
    const sortTable = ({ prop, order }) => {
      sorting.splice(0, sorting.length);

      //TODO : activate multi ordering when available
      sorting.push(...sorting.filter((el) => el.column != prop));

      if (sorting.length == 0 && 1 == 2) {
        sorting.push({
          column: "date",
          order: "desc",
        });
      }

      if (order != null) {
        sorting.push({
          column: prop,
          order: [
            { key: "ascending", value: "asc" },
            { key: "descending", value: "desc" },
          ].find((el) => el.key === order).value,
        });
      }
    };

    /* Table settings */
    const pagination = reactive({
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      total: 0,
    });

    const searchQuery = ref("");

    const fetchTableData = async () => {
      await store
        .dispatch("activities/fetchActivities", {
          page: pagination.currentPage,
          perPage: pagination.perPage,
          searchTerm: searchQuery.value,
          searchFields: propsToSearch,
          orderId:
            props.filter.find((element) => element.key == "orderId")?.value ??
            null,
          sorting,
        })
        .then((result) => {
          pagination.total = result.total;
        });
    };

    watch(
      [
        sorting,
        () => pagination.perPage,
        () => pagination.currentPage,
        searchQuery,
      ],
      _.debounce(async () => {
        fetchTableData();
      }, 500)
    );

    onMounted(() => {
      fetchTableData();

      store.dispatch("persons/fetchPerson", store.state.user.person.id);

      const orderId =
        props.filter.find((element) => element.key == "orderId")?.value ?? null;
      if (orderId) {
        store.dispatch(
          "orders/fetchOrderById",
          props.filter.find((element) => element.key == "orderId")?.value ??
            null
        );
      } else {
        store.dispatch("orders/fetchOrders", {
          page: 1,
          perPage: 10,
        });
      }
    });

    /* Delete modal */
    const deleteModal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const deleteSuccessModal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    const promptDelete = (activityId) => {
      deleteModal
        .fire({
          icon: "question",
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "Cancel",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            store
              .dispatch("activities/deleteActivity", activityId)
              .then(async ({ result, message }) => {
                if (result === true) {
                  pagination.total = (
                    await store.dispatch("activities/fetchActivities", {
                      page: 1,
                      perPage: pagination.perPage,
                      orderId:
                        props.filter.find((element) => element.key == "orderId")
                          ?.value ?? null,
                    })
                  )?.total;

                  deleteSuccessModal.fire({
                    icon: "success",
                    title: "Deleted!",
                    text: "The activity has been deleted.",
                  });
                } else {
                  console.log(message);
                  //errorMessage.value = message;
                }
              });
          }
        });
    };
    /* Modal */
    const showModal = ref(false);
    const handleAddActivity = () => {
      store.dispatch("persons/fetchPersonById", store.state.user.id);

      modalModeInsert.value = true;

      modalDataToShow.entityId.value = ((val) => (val ? "order_" + val : val))(
        props.filter.find((element) => element.key == "orderId")?.value
      );
      modalDataToShow.date.value = new Date().toISOString().substr(0, 10);
      modalDataToShow.description.value = null;
      modalDataToShow.duration.value = null;
      modalDataToShow.personId.value = store.state.user.id;
      modalDataToShow.type.value = null;
      modalDataToShow.billable.value = false;
      modalDataToShow.duration.value = null;

      showModal.value = true;
    };
    const modalModeInsert = ref(false);

    return {
      sorting,
      sortTable,
      promptDelete,
      modalModeInsert,
      handleAddActivity,
      submitAction,
      pagination,
      searchQuery,
      tableColumns,
      tableData: computed(() => store.state.activities.activities),
      showModal,
      modalDataToShow,
    };
  },
  computed: {
    pagedData() {
      return this.tableData /*.slice(this.from, this.to)*/;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total() < highBound) {
        highBound = this.total();
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  methods: {
    total() {
      return this.pagination.total;
    },
    handleShow(index, row) {
      this.modalModeInsert = false;

      const rowdata = this.$store.getters["activities/getActivity"](row.id);

      this.$store.dispatch("users/fetchUser", rowdata.user.id).then(() => {
        this.modalDataToShow.createdBy.value =
          this.$store.state.users.user.firstName +
          " " +
          this.$store.state.users.user.lastName;
      });
      this.$store
        .dispatch("persons/fetchPerson", rowdata.person.id)
        .then(() => {
          this.modalDataToShow.personId.value =
            this.$store.state.persons.person.firstName +
            " " +
            this.$store.state.persons.person.lastName;
        });

      this.modalDataToShow.entityId.value =
        rowdata.order?.name ??
        rowdata.contact.firstName + " " + rowdata.contact.lastName;

      this.modalDataToShow.date.value = rowdata.date;
      this.modalDataToShow.description.value = rowdata.description;
      this.modalDataToShow.duration.value = rowdata.duration;

      this.modalDataToShow.type.value = activityTypes.find(
        (el) => el.key === rowdata.type
      )?.label;
      this.modalDataToShow.billable.value = ["No", "Yes"][rowdata.billable];
      this.modalDataToShow.duration.value = rowdata.duration;

      this.showModal = true;
    },
    handleDelete(index, row) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.promptDelete(row.id);
        //this.tableData.splice(indexToDelete, 1);
      }
    },
  },
});
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
