<template>
  <div class="card card-user">
    <div class="image">
      <img src="https://picsum.photos/400" alt="..." />
    </div>
    <div class="card-body">
      <div class="author">
        <router-link :to="`/contacts/${contact?.type}/details/${contact?.id}`">
          <avatar class="avatar border-gray" :text="fullname" />
          <h5 class="title">
            {{ contact?.firstName }} {{ contact?.lastName }}
          </h5>
        </router-link>
        <!--<p class="description">@chetfaker</p>-->
      </div>
      <!--<p class="description text-center">
        "I like the way you work it
        <br />
        No diggity <br />
        I wanna bag it up"
      </p>-->
    </div>
    <div class="card-footer">
      <hr />
      <div class="button-container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6 ml-auto">
            <el-tooltip
              :content="quickInfo[type].first.help"
              :show-after="300"
              placement="top"
            >
              <h5>
                {{ `${quickInfo[type].first.value.value}` }}
                <br />
                <small>{{ quickInfo[type].first.title }}</small>
              </h5>
            </el-tooltip>
          </div>
          <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
            <el-tooltip
              :content="quickInfo[type].second.help"
              :show-after="300"
              placement="top"
            >
              <h5>
                {{ quickInfo[type].second.value }}
                <br />
                <small>{{ quickInfo[type].second.title }}</small>
              </h5>
            </el-tooltip>
          </div>
          <div class="col-lg-3 mr-auto">
            <el-tooltip
              :content="quickInfo[type].third.help"
              :show-after="300"
              placement="top"
            >
              <h5>
                {{ quickInfo[type].third.value.value }}
                <br />
                <small>{{ quickInfo[type].third.title }}</small>
              </h5>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import mixin from "./mixin";
import { ElTooltip } from "element-plus";
import { Avatar } from "src/components/UIComponents";
//import { useRoute } from "vue-router";

export default {
  components: {
    ElTooltip,
    Avatar,
  },
  mixins: [mixin],
  props: {
    id: {
      type: Number,
      default: null,
      description: "Id of the contact",
    },
  },
  setup(props) {
    const store = useStore();

    onMounted(() => {
      if (props.id) {
        store.dispatch("contacts/fetchContact", props.id);
      }
    });

    const contact = computed(() => store.state.contacts.contact);

    const quickInfo = {
      customer: {
        //customer
        first: {
          title: "Orders",
          value: computed(() => contact.value?.stats?.orders ?? 0),
          help: "Number of orders",
        },
        second: {
          title: "Total value",
          value: computed(() => contact.value?.stats?.ordersValue ?? 0),
          help: "(€) Sum of every order value",
        },
        third: {
          title: "KPI",
          value: computed(() =>
            (
              ((contact.value?.stats?.ordersCompleted +
                contact.value?.stats?.ordersPending) /
                contact.value?.stats?.orders) *
                100 || 0
            ).toFixed(2)
          ),
          help: "(%) Orders engagement ratio (completed + pending ) / total",
        },
      },
      supplier: {
        //supplier
        first: {
          title: "Cost",
          value: computed(() => contact.value?.stats?.cost ?? 0),
          help: "Sum of this year costs",
        },
        second: {
          title: "Last year",
          value: computed(() => contact.value?.stats?.costLastYear ?? 0),
          help: "Sum of precedent year costs",
        },
        third: {
          title: "Last cost",
          value: computed(() => contact.value?.stats?.lastCost ?? 0),
          help: "Last billed purchase",
        },
      },
      contact: {
        //contact
        first: {
          title: "Last contact",
          value: computed(() => contact.value?.stats?.lastContact ?? "N/A"),
          help: "Last interaction with this contact",
        },
        second: {
          title: "Pending orders",
          value: computed(() => contact.value?.stats?.ordersPending ?? 0),
          help: "Pending orders with this contact",
        },
        third: {
          title: "KPI",
          value: computed(() => contact.value?.stats?.contactRatio ?? 0),
          help: "Interactions per month",
        },
      },
    };

    return {
      //businessImage,
      contact,
      quickInfo,
      fullname: computed(
        () => `${contact.value?.firstName} ${contact.value?.lastName}`
      ),
    };
  },
};
</script>
<style></style>
