export default {
  props: {
    type: {
      type: String,
      default: "contact",
      description: "Type of the contact list: customer|supplier|contact",
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ["customer", "supplier", "contact"].indexOf(value) !== -1;
      },
    },
  },
};
