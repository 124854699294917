<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6">
          <h5>Persons assigned</h5>
        </div>
        <div class="col-md-6">
          <div class="float-right">
            <p-button @click="showModal = true">New assignment</p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <el-table
        class="table-striped"
        :data="assigned.data"
        border
        style="width: 100%"
        max-height="300"
        :default-sort="{
          prop: assigned.sorting.column,
          order: assigned.sorting.order,
        }"
      >
        <el-table-column
          v-for="column in assigned.tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :width="column.width"
          :prop="column.prop"
          :label="column.label"
          :sortable="column.sortable"
        >
          <template v-if="column.customLayout === 'progress'" #default="scope">
            <Progress
              v-if="scope.row.committedTime >= 0"
              :type="
                (scope.row.committedTime * 100) / scope.row.estimatedTime > 100
                  ? 'danger'
                  : 'primary'
              "
              :value="(scope.row.committedTime * 100) / scope.row.estimatedTime"
            ></Progress>
          </template>
        </el-table-column>
        <el-table-column
          :min-width="120"
          fixed="right"
          class-name="td-actions"
          label="Actions"
        >
          <template #default="props">
            <p-button
              type="info"
              size="sm"
              icon
              @click="handleShow(props.$index, props.row)"
            >
              <i class="fa fa-user"></i>
            </p-button>
            <p-button
              type="danger"
              size="sm"
              icon
              @click="handleRemove(props.$index, props.row)"
            >
              <i class="fa fa-times"></i>
            </p-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <modal class="modal-primary" :show="showModal" @close="showModal = false">
      <template #header>
        <div class="modal-profile ml-auto mr-auto">
          <i class="nc-icon nc-badge"></i>
        </div>
      </template>
      <fg-input v-model="newAssignment" label="Search a person" required>
        <template #default="slotProps">
          <el-select
            v-model="slotProps.inputValue"
            class="select-default"
            filterable
            remote
            :placeholder="`Search for a candidate`"
            :remote-method="searchPerson"
            :loading="loading"
            @change="slotProps.updateValue($event)"
          >
            <el-option
              v-for="item in candidates"
              :key="item.id"
              :label="item.firstName + ' ' + item.lastName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </fg-input>
      <fg-input
        v-model="newAssignmentAmount"
        label="Estimated effort"
        type="number"
        required
        @update:modelValue="newAssignmentAmount = $event"
      >
      </fg-input>
      <template #footer>
        <div class="left-side">
          <p-button type="default" link @click="showModal = false"
            >Back</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button
            type="default"
            :disabled="newAssignmentAmount <= 0 || newAssignment === null"
            link
            @click="onAssign()"
            >Assign</p-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, computed, reactive, ref } from "vue";
import { Progress, Modal } from "src/components/UIComponents";
import { useRouter } from "vue-router";

import Swal from "sweetalert2";

export default {
  components: {
    Progress,
    Modal,
  },
  props: {
    id: {
      type: Number,
      default: null,
      description: "Order id",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const showModal = ref(false);
    const loading = ref(false);

    onMounted(() => {
      store.dispatch("orders/fetchPersons", {
        orderId: props.id,
        page: assigned.pagination.currentPage,
        perPage: assigned.pagination.perPage,
        orderBy: assigned.sorting.column,
        sortOrder: assigned.sorting.order,
        withStats: true,
      });
    });

    /* Table actions */
    const handleRemove = (index, row) => {
      let indexToDelete = assigned.data.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        promptDelete(row.id);
        //this.tableData.splice(indexToDelete, 1);
      }
    };

    /* Delete modal */
    const deleteModal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const deleteSuccessModal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    const deleteFailedModal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    const promptDelete = (personId) => {
      deleteModal
        .fire({
          icon: "question",
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "Cancel",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            store
              .dispatch("orders/unassignPerson", {
                orderId: props.id,
                personId: personId,
              })
              .then(async ({ result, message }) => {
                if (result === true) {
                  store.dispatch("orders/fetchPersons", {
                    orderId: props.id,
                    page: assigned.pagination.currentPage,
                    perPage: assigned.pagination.perPage,
                    orderBy: assigned.sorting.column,
                    sortOrder: assigned.sorting.order,
                    withStats: true,
                  });

                  deleteSuccessModal.fire({
                    icon: "success",
                    title: "Deleted!",
                    text: "The person has been unassigned.",
                  });
                } else {
                  console.log(message);

                  deleteFailedModal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Something went wrong.",
                  });
                }
              });
          }
        });
    };

    /* Assign Modal */
    /* Assign Modal - Search candidate input */
    const candidates = computed(() => store.state.persons.persons);
    const newAssignment = ref(null);
    const newAssignmentAmount = ref(0);
    const searchPerson = (query) => {
      if (query) {
        loading.value = true;
        store
          .dispatch("persons/fetchPersons", {
            perPage: 10,
            searchTerm: query,
            searchFields: ["firstName", "lastName"],
          })
          .then(() => {
            loading.value = false;
          })
          .catch(() => (loading.value = false));
      } else {
        candidates.value = [];
      }
    };

    /* Assign Modal - Assign */
    const onAssign = () => {
      store
        .dispatch("orders/assignPerson", {
          orderId: props.id,
          personId: newAssignment.value,
          amount: newAssignmentAmount.value,
        })
        .then((res) => {
          if (res.result) {
            showModal.value = false;

            store.dispatch("orders/fetchPersons", {
              orderId: props.id,
              page: assigned.pagination.currentPage,
              perPage: assigned.pagination.perPage,
              orderBy: assigned.sorting.column,
              sortOrder: assigned.sorting.order,
              withStats: true,
            });
          }
        });
    };

    /* Assigned resources */
    const assigned = reactive({
      data: computed(() => store.state.orders.persons),
      pagination: { perPage: null, currentPage: null },
      sorting: { prop: "firstName", order: "asc" },
      tableColumns: [
        {
          prop: "firstName",
          label: "First Name",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "lastName",
          label: "Last name",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "companyRole",
          label: "Company role",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "committedTime",
          label: "Committed",
        },
        {
          prop: "estimatedTime",
          label: "Estimated",
        },
        {
          prop: "estimatedTime",
          label: "Effort",
          customLayout: "progress",
          minWidth: 120,
        },
      ],
    });

    /* Goto person details */
    const handleShow = (index, row) => {
      router.push(`/persons/details/${row.id}`);
    };

    return {
      assigned,
      promptDelete,
      handleRemove,
      showModal,
      searchPerson,
      loading,
      candidates,
      newAssignment,
      newAssignmentAmount,
      onAssign,
      handleShow,
    };
  },
};
</script>
<style></style>
