<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title capitalize">{{ mode }} {{ type }}</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <fg-input
                  type="text"
                  label="Company"
                  :disabled="false"
                  placeholder="Company name"
                  name="companyName"
                >
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input
                  type="email"
                  label="Email"
                  placeholder="Email"
                  name="email"
                >
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <fg-input
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                >
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                >
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <fg-input
                  type="text"
                  label="Address"
                  placeholder="Address"
                  name="address"
                >
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <fg-input
                  :model-value="contacts.referralId"
                  type="number"
                  label="Referral"
                  placeholder="Refer a contact"
                  name="referralId"
                >
                  <template #default="slotProps">
                    <el-select
                      v-model="slotProps.inputValue"
                      filterable
                      remote
                      class="select-default"
                      reserve-keyword
                      placeholder="Refer a contact"
                      :remote-method="searchContacts"
                      :loading="loading"
                      @change="slotProps.updateValue($event)"
                    >
                      <el-option
                        v-for="item in contacts"
                        :key="item.id"
                        class="select-default"
                        :label="`${item.firstName} ${item.lastName}`"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </fg-input>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <fg-input
              :model-value="contacts.notes"
              type="text"
              name="notes"
              label="Notes"
            >
              <template #default="slotProps">
                <el-input
                  v-model="slotProps.inputValue"
                  class="form-control"
                  placeholder="Write some notes"
                  :rows="5"
                  maxlength="1024"
                  type="textarea"
                  @input="slotProps.updateValue($event)"
                ></el-input>
                <label
                  ><small
                    >{{ slotProps.inputValue?.length ?? 0 }} / 1024</small
                  ></label
                >
              </template>
            </fg-input>
          </div>
        </div>
        <div v-if="mode === 'edit'" class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            :disabled="isSubmitting"
            @click.prevent="onSubmit"
          >
            Update Contact
          </button>
        </div>

        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { object, string, number } from "yup";
import { useForm } from "vee-validate";
import { computed, ref, onMounted, watch } from "vue";
import mixin from "./mixin";

export default {
  mixins: [mixin],
  props: {
    id: {
      type: Number,
      default: null,
      description: "Id of the contact",
    },
    mode: {
      type: String,
      default: "edit",
      description: "form in show|edit|create mode",
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ["show", "edit", "create"].indexOf(value) !== -1;
      },
    },
  },
  setup(props) {
    const store = useStore();

    onMounted(() => {
      if (props.id) {
        store.dispatch("contacts/fetchContact", props.id).then(() => {});
      }
    });

    const contact = computed(() => store.state.contacts.contact);

    watch(contact, (current) => {
      if (current.referralId) {
        store.dispatch(
          "contacts/fetchContactById",
          store.state.contacts.contact.referralId
        );
      }
    });

    const contacts = computed(() =>
      store.getters["contacts/getContactsByType"]("contact")
    );

    const defaults = ref(contact);

    const schema = object().shape({
      companyName: string().required("This field is required"),
      firstName: string().required("This field is required"),
      lastName: string().required("This field is required"),
      email: string()
        .email("This is not a valid email")
        .required("This field is required"),
      address: string().nullable(true),
      referralId: number()
        .transform((currentValue, originalValue) => {
          return originalValue === "" ? null : currentValue;
        })
        .nullable(true),
      notes: string().nullable(true),
    });

    const { handleSubmit, validate, values } = useForm({
      validationSchema: schema,
      initialValues: defaults,
    });

    const isSubmitting = ref(false);
    const onSubmit = handleSubmit((values) => {
      isSubmitting.value = true;
      store
        .dispatch("contacts/updateContact", {
          contactValues: values,
          id: props.id,
        })
        .then(({ result }) => {
          if (result === true) {
            console.log("show message");
          }
        })
        .finally(() => (isSubmitting.value = false));
    });

    const loading = ref(false);
    const searchContacts = (query) => {
      if (query) {
        loading.value = true;
        store.dispatch("contacts/fetchContacts", {
          type: "contact",
          page: 1,
          perPage: 10,
          searchTerm: query,
          searchFields: ["firstName", "lastName"],
        });
        loading.value = false;
      } else {
        contacts.value = [];
      }
    };

    return {
      onSubmit,
      contact,
      contacts,
      loading,
      isSubmitting,
      searchContacts,
      async validate() {
        return { result: await validate(), values: values };
      },
    };
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
