<template>
  <div class="row">
    <div class="col-lg-12 col-md-7">
      <edit-contact-form ref="contactForm" :mode="'create'" :type="type">
      </edit-contact-form>
    </div>
    <div class="col-lg-12 text-center">
      <p-button :disabled="isSubmitting" @click="onSubmit"> Submit </p-button>
    </div>
  </div>
</template>
<script>
import EditContactForm from "./EditContactForm.vue";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import mixin from "./mixin.js";
import { useRouter } from "vue-router";

export default {
  components: {
    EditContactForm,
  },
  mixins: [mixin],
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();

    onBeforeMount(() => store.dispatch("contacts/clearContact"));

    const contactForm = ref(null);

    const isSubmitting = ref(false);
    const onSubmit = async () => {
      isSubmitting.value = true;
      const { result: contactResult, values: contactValues } =
        await contactForm.value.validate();

      if (contactResult.valid === true) {
        store
          .dispatch("contacts/createContact", {
            ...contactValues,
            type: props.type,
          })
          .then(({ result, message, id }) => {
            if (result === true) {
              // reset the form and the field values to their initial values
              //resetForm();
              router.push(`/contacts/${props.type}/details/${id}`);
            } else {
              //errorMessage.value = message;
              console.log(message);
            }
          })
          .catch(() => (isSubmitting.value = false));
      } else {
        isSubmitting.value = false;
      }
    };

    return {
      contactForm,
      onSubmit,
      isSubmitting,
    };
  },
};
</script>
<style></style>
