<template>
  <modal
    :key="hackKey"
    :show="modalToggle"
    class="modal-primary"
    :show-close="showClose"
    header-classes="justify-content-center"
    type="mini"
    @close="$emit('onClose')"
  >
    <template #header>
      <div class="modal-profile ml-auto mr-auto">
        <i class="nc-icon nc-time-alarm"></i>
      </div>
    </template>
    <template v-for="input in activityFields" :key="input.name">
      <component
        :is="formType"
        v-if="input.editable !== false"
        :model-value="input.value?.value"
        :label="input.label"
        :type="input.type"
        :name="input.name"
        :disabled="input.disabled?.value"
        v-bind="input.attrs"
      >
        <p v-if="!modeInsert">
          <label>{{ input.label }}</label>
          {{ input.value?.value }}
        </p>
        <template
          v-if="input.mode === 'select' && modeInsert"
          #default="slotProps"
        >
          <el-select
            v-model="slotProps.inputValue"
            class="select-default"
            filterable
            remote
            :disabled="input.disabled?.value"
            reserve-keyword
            :placeholder="`Search for ${input.label}`"
            :remote-method="input.remote?.datasource"
            :loading="input.remote?.loading"
            @change="slotProps.updateValue($event)"
          >
            <el-option
              v-for="item in input.remote?.data.value ?? input.options"
              :key="item.key"
              class="select-default"
              :label="item.label"
              :value="item.key"
            >
              <template v-if="input.customTemplate == 'tag-list'">
                <Tag class="mr-3" :type="'activity-' + item.key">
                  {{ item.label[0] }}
                </Tag>
                <span>{{ item.label }}</span>
              </template>
              <template v-if="!input.customTemplate && item.subLabel">
                {{ item.label }}
                <small class="text-info">{{ item.subLabel }}</small>
              </template>
            </el-option>
          </el-select>
        </template>
      </component>
    </template>
    <template #footer>
      <div class="left-side">
        <p-button type="default" link @click="modalToggle = false"
          >Back</p-button
        >
      </div>
      <div class="divider"></div>
      <div v-if="modeInsert" class="right-side">
        <p-button :disabled="isSubmitting" type="default" link @click="onSave()"
          >Save</p-button
        >
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "src/components/UIComponents";
import { defineComponent, ref, computed, watch } from "vue";
import { useForm } from "vee-validate";
import { useStore } from "vuex";
import { object, string, date, number, bool } from "yup";
import { Tag } from "src/components/UIComponents";

const activityTypes = [
  { key: "development", label: "Development" },
  { key: "call", label: "Call" },
  { key: "meeting", label: "Meeting" },
  { key: "helpdesk", label: "Helpdesk" },
];

export default defineComponent({
  components: {
    Modal,
    Tag,
  },
  props: {
    showClose: {
      type: Boolean,
      default: false,
      description: "Shows or hides the close button",
    },
    showModal: {
      type: Boolean,
      default: false,
      description: "Shows or hides the modal",
    },
    modeInsert: {
      type: Boolean,
      default: true,
      description: "A flag used to set if the fields are just labels or inputs",
    },
    submitAction: {
      type: Function,
      default: null,
      description: "Callback called if validation succeed",
    },
    shouldResetAfter: {
      type: Boolean,
      default: false,
      description: "A flag which clears any form after a submit action",
    },
    data: {
      type: Object,
      default: null,
      description: "Initial form data",
    },
  },
  emits: ["onSave", "onClose"],

  setup(props, { emit }) {
    const store = useStore();
    const modalToggle = ref(false);

    watch(
      () => props.showModal,
      (newVal) => (modalToggle.value = newVal)
    );

    watch(modalToggle, (newVal) => {
      if (!newVal) {
        emit("onClose");
      }
    });

    /* Validation */
    const schema = object().shape(
      {
        entityId: string().required("This field is required"),
        date: date()
          .required()
          .typeError("This field must be a valid date dd/mm/yyyy"),
        description: string().nullable(true).required("This field is required"),
        duration: number()
          .positive()
          .required()
          .typeError("This field is required"),
        personId: number().required(),
        type: string().required().typeError("This field is required"),
        billable: bool().default(false),
      },
      [["orderId", "contactId"]]
    );

    const formType = computed(() => (props.modeInsert ? "fg-input" : "p"));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: schema,
    });

    const isSubmitting = ref(false);
    const onSave = handleSubmit((values) => {
      if (props.shouldResetAfter === true) {
        resetForm();

        hackKey.value++;
      }
      isSubmitting.value = true;
      props.submitAction?.(values);

      modalToggle.value = false;
      isSubmitting.value = false;
    });

    /* Remote data sources */
    const searchCustomersOrOrder = (query) => {
      if (query) {
        //loading.value = true;
        store.dispatch("contacts/fetchContacts", {
          perPage: 10,
          searchTerm: query,
          searchFields: ["firstName", "lastName", "companyName"],
          type: "customer",
        });
        store.dispatch("orders/fetchOrders", {
          perPage: 10,
          searchTerm: query,
          searchFields: ["name"],
        });
        //loading.value = false;
      } else {
        //customers.value = [];
      }
    };

    const searchPerson = (query) => {
      if (query) {
        //loading.value = true;
        store.dispatch("persons/fetchPersons", {
          perPage: 10,
          searchTerm: query,
          searchFields: ["firstName", "lastName"],
        });
        //loading.value = false;
      } else {
        //customers.value = [];
      }
    };

    const activityFields = computed(() => [
      {
        label: "Order/Client",
        type: "number",
        mode: "select",
        name: "entityId",
        disabled: computed(() => !!props.data.entityId.value),
        value: props.data.entityId,
        remote: {
          loading: false,
          datasource: searchCustomersOrOrder,
          data: computed(() => [
            ...store.getters["contacts/getContactsByType"]("customer").map(
              (element) => ({
                key: "contact" + "_" + element.id,
                label: element.companyName,
                subLabel: element.firstName + " " + element.lastName,
              })
            ),
            ...store.getters["orders/getOrders"].map((element) => ({
              key: "order" + "_" + element.id,
              label: element.name,
              subLabel: element.contact.companyName,
            })),
          ]),
        },
      },
      {
        label: "Date",
        type: "date",
        name: "date",
        value: props.data.date,
      },
      {
        label: "Type",
        type: "text",
        name: "type",
        mode: "select",
        value: props.data.type,
        options: activityTypes,
        customTemplate: "tag-list",
      },
      {
        label: "Duration",
        type: "number",
        name: "duration",
        value: props.data.duration,
      },
      {
        label: "Description",
        type: "text",
        name: "description",
        value: props.data.description,
      },
      {
        label: "Billable",
        type: "checkbox",
        name: "billable",
        value: props.data.billable,
      },
      {
        label: "Assegnee",
        type: "number",
        mode: "select",
        name: "personId",
        value: props.data.personId,
        remote: {
          loading: false,
          datasource: searchPerson,
          data: computed(() =>
            store.getters["persons/getPersons"].map((el) => ({
              key: el.id,
              label: el.firstName + " " + el.lastName,
            }))
          ),
        },
      },
      {
        label: "Created by",
        type: "text",
        name: "createdBy",
        editable: false,
        value: props.data.createdBy,
      },
    ]);

    const hackKey = ref(0);

    return {
      modalToggle,
      formType,
      onSave,
      activityFields,
      isSubmitting,
      hackKey,
    };
  },
});
</script>
