<template>
  <div class="row">
    <div class="col-md-10">
      <h4 class="title">Orders</h4>
    </div>
    <div class="col-md-2 mt-3">
      <p-button :round="true" class="pull-right" @click="handleAddOrder"
        >Create Order</p-button
      >
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Orders</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            v-model="pagination.perPage"
            class="select-default"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-default"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <el-input
              v-model="searchQuery"
              class="input-sm"
              placeholder="Search"
            >
              <template #suffix>
                <el-icon class="el-input__icon nc-icon nc-zoom-split" />
              </template>
            </el-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
            :default-sort="{ prop: sorting.column, order: sorting.order }"
            @sort-change="sortTable($event)"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable"
            >
              <template v-if="column.customLayout" #default="scope">
                <Progress
                  v-if="column.customLayout == 'progress'"
                  :type="
                    ((scope.row.actualTime.billable +
                      scope.row.actualTime.nonbillable) *
                      100) /
                      scope.row.estimatedTime >
                    100
                      ? 'danger'
                      : 'primary'
                  "
                  :value="
                    ((scope.row.actualTime.billable +
                      scope.row.actualTime.nonbillable) *
                      100) /
                    scope.row.estimatedTime
                  "
                >
                </Progress>
                <Tag
                  v-if="column.customLayout == 'tag'"
                  :type="'order-' + scope.row?.status"
                >
                  {{ scope.row?.status }}
                </Tag>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              fixed="right"
              class-name="td-actions"
              label="Actions"
            >
              <template #default="props">
                <p-button
                  type="info"
                  size="sm"
                  icon
                  @click="handleShow(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
                <p-button
                  type="default"
                  size="sm"
                  icon
                  @click="handleAddTime(props.$index, props.row)"
                >
                  <i class="fa fa-clock-o"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total() }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            v-model="pagination.currentPage"
            class="pull-right"
            :per-page="pagination.perPage"
            :total="pagination.total"
            @input="pagination.currentPage = $event"
          >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Progress,
  Pagination as PPagination,
  Tag,
} from "src/components/UIComponents";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch, reactive, inject } from "vue";
import { useRouter } from "vue-router";
import _ from "lodash";

const createConfig = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

export default {
  components: {
    PPagination,
    Progress,
    Tag,
  },
  props: {
    type: {
      type: Array,
      default: () => ["offer", "completed", "working", "blocked", "lost"],
      description: "A filter to show only specific order statuses",
    },
    customerId: {
      type: Number,
      default: null,
      description: "A filter to show only specific customer's orders",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const axios = inject("axios"); // inject axios

    /* Pagination */
    const pagination = reactive({
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      total: 0,
    });

    /* Table sorting */
    const sorting = reactive({
      column: "id",
      order: "asc",
    });
    const sortTable = ({ prop, order }) => {
      if (order != null) {
        sorting.column = prop;
        sorting.order = [
          { key: "ascending", value: "asc" },
          { key: "descending", value: "desc" },
        ].find((el) => el.key === order).value;
      } else {
        sorting.column = "id";
        sorting.order = "asc";
      }
    };

    const searchQuery = ref("");

    watch(
      [
        sorting,
        () => pagination.perPage,
        () => pagination.currentPage,
        searchQuery,
      ],
      _.debounce(async () => {
        pagination.total = (
          await store.dispatch("orders/fetchOrders", {
            page: pagination.currentPage,
            perPage: pagination.perPage,
            searchTerm: searchQuery.value,
            searchFields: ["name", "description"],
            statusFilter: props.type,
            orderBy: sorting.column,
            sortOrder: sorting.order,
            contactId: props.customerId,
          })
        )?.total;
      }, 500)
    );

    const loadOrders = async () => {
      pagination.total = (
        await store.dispatch("orders/fetchOrders", {
          page: pagination.currentPage,
          perPage: pagination.perPage,
          statusFilter: props.type,
          orderBy: sorting.column,
          sortOrder: sorting.order,
          contactId: props.customerId,
        })
      )?.total;
    };

    onMounted(async () => {
      loadOrders();
    });

    watch(
      () => props.type,
      (current, old) => {
        if (current !== old) loadOrders();
      }
    );

    const handleAddOrder = () => {
      router.push("/orders/create");
    };

    return {
      handleAddOrder,
      pagination,
      sortTable,
      sorting,
      searchQuery,
      propsToSearch: ["status", "description", "name", "companyName"],
      tableColumns: [
        {
          prop: "status",
          label: "Status",
          minWidth: 80,
          sortable: "custom",
          customLayout: "tag",
        },
        {
          prop: "name",
          label: "Project name",
          minWidth: 100,
          sortable: "custom",
        },
        {
          prop: "contact.companyName",
          label: "Customer",
          sortable: "custom",
        },
        {
          prop: "description",
          label: "Description",
          minWidth: 250,
        },
        {
          label: "Completion",
          prop: "estimatedTime",
          customLayout: "progress",
          asyncMethod: async (orderId, total) => {
            if (orderId) {
              return await axios
                .get("report", {
                  ...createConfig(store.getters["getToken"]),
                  params: {
                    type: "hours",
                    grouping: ["orderId"],
                    orderId: orderId,
                  },
                })
                .then((res) => {
                  return (res.data[0]?.duration * 100) / total ?? 0;
                })
                .catch(() => {
                  return 0;
                });
            }
          },
        },
      ],
      tableData: computed(() => store.getters["orders/getOrders"]),
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  methods: {
    total() {
      //this.pagination.total = this.tableData.length;
      return this.pagination.total;
    },
    handleShow(index, row) {
      this.$router.push(`/orders/details/${row.id}`);
    },
    /*handleDelete(index, row) {
      alert("not implemented");
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },*/
    handleAddTime(index, row) {
      this.$router.push(`activities/${row.id}`);
    },
  },
};
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
