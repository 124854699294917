<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Custom table with pagination</h4>
      <p class="category">
        We combine
        <a
          href="http://element.eleme.io/#/en-US/component/quickstart"
          target="_blank"
          rel="noopener"
          >Element-UI</a
        >
        table functionalities together with a custom pagination component which
        should provide a very good starting point to integrate tables in your
        application. Check out more functionalities at
        <a
          href="http://element.eleme.io/#/en-US/component/table"
          target="_blank"
          rel="noopener"
          >Element-UI table documentation</a
        >.
      </p>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Extended tables</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            v-model="pagination.perPage"
            class="select-default"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-default"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              v-model="searchQuery"
              class="input-sm"
              placeholder="Search"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="queriedData()"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column
              :min-width="120"
              fixed="right"
              class-name="td-actions"
              label="Actions"
            >
              <template #default="props">
                <p-button
                  type="info"
                  size="sm"
                  icon
                  @click="handleLike(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
                <p-button
                  type="success"
                  size="sm"
                  icon
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total() }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            v-model="pagination.currentPage"
            class="pull-right"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import * as Vue from "vue";
//import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import users from "./users";
//Vue.use(Table);
//Vue.use(TableColumn);
//Vue.use(Select);
//Vue.use(Option);
export default {
  components: {
    PPagination,
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name", "email", "age"],
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 200,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 250,
        },
        {
          prop: "age",
          label: "Age",
          minWidth: 100,
        },
        {
          prop: "salary",
          label: "Salary",
          minWidth: 120,
        },
      ],
      tableData: users,
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  methods: {
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    handleLike(index, row) {
      alert(`Your want to like ${row.name}`);
    },
    handleEdit(index, row) {
      alert(`Your want to edit ${row.name}`);
    },
    handleDelete(index, row) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
