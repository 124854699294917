<template>
  <div class="row">
    <div class="col-lg-4 col-md-5">
      <contact-card :id="id" :type="type"> </contact-card>
      <!--<members-card> </members-card>-->
    </div>
    <div class="col-lg-8 col-md-7">
      <edit-contact-form :id="id" mode="edit" :type="type"> </edit-contact-form>
    </div>
    <div v-if="type === 'customer'" class="col-lg-12 col-md-12">
      <order-list :customer-id="id"> </order-list>
    </div>
  </div>
</template>
<script>
import EditContactForm from "./EditContactForm.vue";
import ContactCard from "./ContactCard.vue";
import OrderList from "../Orders/OrdersList.vue";
//import MembersCard from "./MembersCard.vue";
import { useStore } from "vuex";
import mixin from "./mixin";
import { onMounted, onBeforeMount } from "vue";

export default {
  components: {
    EditContactForm,
    ContactCard,
    OrderList,
  },
  mixins: [mixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    onBeforeMount(() => store.dispatch("contacts/clearContact"));
    onMounted(() => store.dispatch("contacts/fetchContact", props.id));
  },
};
</script>
<style></style>
