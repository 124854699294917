<template>
  <modal
    :show="modalToggle"
    class="modal-primary"
    :show-close="showClose"
    header-classes="justify-content-center"
    type="mini"
    @close="$emit('onClose')"
  >
    <template #header>
      <div class="modal-profile ml-auto mr-auto">
        <i class="nc-icon nc-paper"></i>
      </div>
    </template>
    <label>Summary</label>
    <p>You are currently billing {{ activities.length }} activities</p>
    <p>These are the activites by type</p>
    <template
      v-for="(summaryLine, key) in activitiesSummary.byType"
      :key="summaryLine"
    >
      <div>
        <Tag :type="'activity-' + key">
          {{ activityTypes.find((el) => el.key === key)?.label }}
        </Tag>
        {{ summaryLine }}
      </div>
    </template>
    <p>These are the activites by person</p>
    <ul
      v-for="(summaryLine, key) in activitiesSummary.byPerson"
      :key="summaryLine"
    >
      <li>{{ key }} : {{ summaryLine }}</li>
    </ul>
    <br />
    <el-divider />
    <p><label>Hours</label></p>
    <p>This is the amount of hours which will be billed</p>
    <template
      v-for="(summaryLine, key) in activitiesSummary.hours"
      :key="summaryLine"
    >
      <div>
        <Tag :type="'activity-' + key">
          {{ activityTypes.find((el) => el.key === key)?.label }}
        </Tag>
        {{ summaryLine }}
      </div>
    </template>
    <br />
    <el-divider />
    <p class="text-center">Total {{ activitiesSummary.total }}</p>

    <template #footer>
      <div class="left-side">
        <p-button type="default" link @click="modalToggle = false"
          >Back</p-button
        >
      </div>
      <div class="divider"></div>
      <div class="right-side">
        <p-button :disabled="isSubmitting" type="default" link @click="onSave()"
          >Save</p-button
        >
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from "src/components/UIComponents";
import { defineComponent, ref, /*computed,*/ watch, onMounted } from "vue";
import { useStore } from "vuex";
import { Tag } from "src/components/UIComponents";

export default defineComponent({
  components: {
    Modal,
    Tag,
  },
  props: {
    showClose: {
      type: Boolean,
      default: false,
      description: "Shows or hides the close button",
    },
    showModal: {
      type: Boolean,
      default: false,
      description: "Shows or hides the modal",
    },
    submitAction: {
      type: Function,
      default: null,
      description: "Callback called if validation succeed",
    },
    activities: {
      type: Object,
      default: null,
      description: "Initial form data",
    },
  },
  emits: ["onSave", "onClose"],

  setup(props, { emit }) {
    const store = useStore();
    const modalToggle = ref(false);

    const activityTypes = [
      { key: "development", label: "Development" },
      { key: "call", label: "Call" },
      { key: "meeting", label: "Meeting" },
      { key: "helpdesk", label: "Helpdesk" },
    ];

    onMounted(() => updateReport());

    watch(
      () => props.showModal,
      (newVal) => (modalToggle.value = newVal)
    );

    watch(modalToggle, (newVal) => {
      if (!newVal) {
        emit("onClose");
      }
    });

    const isSubmitting = ref(false);
    const onSave = () => {
      isSubmitting.value = true;

      store.dispatch(
        "activities/massBill",
        props.activities.map((el) => el.id)
      );

      modalToggle.value = false;
      isSubmitting.value = false;

      emit("onSave");
    };

    /* summary */
    const activitiesSummary = ref({ total: 0 });
    const updateReport = () => {
      activitiesSummary.value.total = props.activities.reduce(
        (previousValue, currentValue) => previousValue + currentValue.duration,
        0
      );

      activitiesSummary.value.byType = props.activities.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          ...{
            [currentValue.type]: previousValue[currentValue.type] + 1 || 1,
          },
        }),
        {}
      );

      activitiesSummary.value.byPerson = props.activities.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          ...{
            [currentValue.person.firstName +
            " " +
            currentValue.person.lastName]:
              previousValue[
                currentValue.person.firstName +
                  " " +
                  currentValue.person.lastName
              ] + 1 || 1,
          },
        }),
        {}
      );

      activitiesSummary.value.hours = props.activities.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          ...{
            [currentValue.type]:
              previousValue[currentValue.type] + currentValue.duration ||
              currentValue.duration,
          },
        }),
        {}
      );
    };

    watch(
      () => props.activities,
      () => updateReport()
    );

    return {
      modalToggle,
      onSave,
      isSubmitting,
      activitiesSummary,
      activityTypes,
    };
  },
});
</script>
