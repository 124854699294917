<template>
  <div class="row">
    <div class="col-lg-8 col-md-7">
      <edit-user-form ref="userForm" :mode="'create'"> </edit-user-form>
    </div>
    <div class="col-lg-4 col-md-5">
      <roles-card ref="userRoles" :mode="'create'"> </roles-card>
    </div>
    <div class="col-lg-12 text-center">
      <p-button :disabled="isSubmitting" @click="onSubmit"> Submit </p-button>
    </div>
  </div>
</template>
<script>
import EditUserForm from "./EditUserForm.vue";
import RolesCard from "./RolesCard.vue";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    EditUserForm,
    RolesCard,
  },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const userForm = ref(null);
    const userRoles = ref(null);

    onBeforeMount(() => store.dispatch("users/clearUser"));

    const isSubmitting = ref(false);
    const onSubmit = () => {
      const userValidate = userForm.value.validate();
      const rolesValidate = userRoles.value.validate();

      isSubmitting.value = true;
      Promise.all([userValidate, rolesValidate]).then(
        ([
          { result: userResult, values: userValues },
          { result: roleResult, values: roleValues },
        ]) => {
          if (userResult.valid === true && roleResult.valid === true) {
            store
              .dispatch("users/createUser", { userValues, roleValues })
              .then(({ result, message, id }) => {
                if (result === true) {
                  // reset the form and the field values to their initial values
                  //resetForm();
                  router.push(`/admin/users/details/${id}`);
                } else {
                  console.log(message);
                  //errorMessage.value = message;
                }
              });
          } else {
            isSubmitting.value = false;
          }
        }
      );
    };

    return {
      userForm,
      userRoles,
      onSubmit,
      isSubmitting,
    };
  },
};
</script>
<style></style>
